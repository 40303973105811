import React, {
	useState,
	useEffect,
	createContext,
	useContext,
	useCallback,
	useMemo,
} from "react";
import { useLocation,useNavigate } from "react-router-dom";
import { BrowserUtils, InteractionStatus } from "@azure/msal-browser";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { getMyInfo } from "../api/users";
import { getMyHeaderMenu, getMySideMenu } from "../api/menu";

function AuthProvider(props) {
	const { instance, inProgress } = useMsal();
	const [user, setUser] = useState(null);
	const [header, setHeader] = useState([]);
	const [side, setSide] = useState([]);

	const [loading, setLoading] = useState(true);
	const isAuthenticated = useIsAuthenticated();

	const { pathname, location } = useLocation();
	let navigate = useNavigate();
	const [category, setCategory] = useState();

	useEffect(() => {
		if (isAuthenticated && inProgress === InteractionStatus.None) {
			if (!user) {
				(async function () {
					const result = await getMyInfo();
					if (result) {
						setUser(result);
            setLoading(false);
					} else {
            navigate("/Unauthorized", { replace: true, state: { from: location } });
          }
				})();
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAuthenticated, inProgress]);

	useEffect(() => {
		if (isAuthenticated) {
			if (header.length === 0) {
				(async function () {
					const result = await getMyHeaderMenu();
					if (result) {
						setHeader(result);
					}
				})();
			}
		}
	}, [isAuthenticated, header]);

	useMemo(() => {
		if (pathname) {
			setCategory(pathname.split("/").filter(Boolean)[0]);
		}
	}, [pathname]);

	useMemo(() => {
		if (category) {
			(async function () {
				const result = await getMySideMenu(category);
				if (result) {
					setSide(result);
				}
			})();
		}
	}, [category]);

	const signOut = useCallback(() => {
		instance.logoutRedirect({
			account: instance.getActiveAccount(),
			onRedirectNavigate: () => !BrowserUtils.isInIframe(),
		});
		setUser();
	}, [instance]);

	return (
		<AuthContext.Provider
			value={{ user, header, side, signOut, loading }}
			{...props}
		/>
	);
}

const AuthContext = createContext({});
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
