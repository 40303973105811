import HeaderNavbar from "components/bootstrap/navbar/header-navbar";
import Sidebar from "components/bootstrap/sidebar/sidebar";
import React from "react";
import { Outlet } from "react-router-dom";

export default function FixedSidebarFlatStyle() {
	
	return (
		<div className="wrapper">
			<HeaderNavbar />
			<Sidebar
				brandLogo={`${process.env.PUBLIC_URL}/Picture7-trimmy.png`}
				search
			/>
			<Outlet />

			<footer className="main-footer">
				<div className="float-right d-none d-sm-inline-block">
					<b>Version</b> 0.0.1
				</div>
			</footer>
		</div>
	);
}
