
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router } from 'react-router-dom';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { MsalProvider } from "@azure/msal-react";
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { setRTL, setLanguage } from '@fluentui/react/lib/Utilities';
import { msalConfig } from './authorize/AuthorizeConfig';
import { Providers } from '@microsoft/mgt-react'
import { Msal2Provider } from '@microsoft/mgt-msal2-provider';
import { locale } from "devextreme/localization"


import App from './App';
import reportWebVitals from './reportWebVitals';

setRTL(false);
setLanguage(document.documentElement.lang);
initializeIcons();
locale(document.documentElement.lang);

export const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}
msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
    }
});

Providers.globalProvider = new Msal2Provider({ publicClientApplication: msalInstance });
const rootElement = document.getElementById('root');

ReactDOM.render(
    <MsalProvider instance={msalInstance}>
        <Router>
            <App />
        </Router>
    </MsalProvider>,
    rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
