import React, { useState, useMemo } from "react";
import DataGrid, {
	Paging,
	Editing,
	Column,
	Form,
	Toolbar,
	Item,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { getUserWithGraphApi, addUser, removeUser } from "api/users";
import {
	getAllCompanies,
	getCompanyAssignedUsersWithGraphApi,
} from "api/company";
import {
	Persona,
	PersonaSize,
	PersonaPresence,
} from "@fluentui/react/lib/Persona";
import { PeoplePicker, UserType } from "@microsoft/mgt-react";
import { Dropdown } from "@fluentui/react";
import ContentHeader from "components/bootstrap/content-header/content-header";
import { Container } from "react-bootstrap";

export default function UserBrowser() {
	const [companies, setCompanies] = useState([]);
	const [selectedCompany, setSelectedCompany] = useState();

	useMemo(() => {
		(async function () {
			const result = await getAllCompanies();
			if (Array.isArray(result)) {
				setCompanies(
					result.map((item) => ({ key: item.code, text: item.name }))
				);
				setSelectedCompany(result?.find((c) => true)?.code);
			}
		})();
	}, []);

	const store = new CustomStore({
		key: "id",
		byKey: (key) => getUserWithGraphApi(key),
		load: (loadOptions) => getCompanyAssignedUsersWithGraphApi(selectedCompany),
		insert: (values) => addUser(values, selectedCompany),
		remove: (key) => removeUser(key, selectedCompany),
	});

	return (
		<div className="content-wrapper" onTransitionEnd={() => {}}>
			<ContentHeader pageName={"사용자"} disableShare/>
			<section className="content">
				<Container fluid>
					<DataGrid
						dataSource={store}
						showBorders={true}
						showRowLines={true}
						rowAlternationEnabled={true}
						columnAutoWidth={true}
						onInitNewRow={(e) => {
							e.data.isActive = true;
							e.data.companyCode = selectedCompany;
						}}
					>
						<Paging enabled={true} />
						<Editing mode="form" allowUpdating={true} allowAdding={true}>
							<Form>
								<Item dataField="id" isRequired={true} />
							</Form>
						</Editing>
						<Column
							dataField="id"
							caption="이름"
							visible={false}
							editCellRender={UserPicker}
						/>
						<Column
							caption="이름"
							dataField="displayName"
							cellRender={(cellData) => (
								<Persona
									imageUrl={cellData.data.imageUrl}
									text={cellData.data.displayName}
									size={PersonaSize.size24}
									presence={PersonaPresence.none}
									hidePersonaDetails={false}
								/>
							)}
						/>
						<Column
							caption="사번"
							dataField="employeeId"
							allowEditing={false}
						/>
						<Column caption="이메일" dataField="mail" allowEditing={false} />
						<Column
							caption="직책/직급"
							dataField="jobTitle"
							allowEditing={false}
						/>
						<Column
							caption="회사"
							dataField="companyName"
							allowEditing={false}
						/>
						<Column
							caption="마지막 로그인(UTC)"
							dataField="lastLogonTimeUtc"
							dataType="datetime"
							allowEditing={false}
							visible={false}
						/>
						<Toolbar>
							<Item location="before">
								<Dropdown
									placeholder="Select a Company"
									options={companies}
									styles={{ dropdown: { width: 200 } }}
									defaultSelectedKey={companies?.find((c) => true)?.key}
									onChange={(event, option, index) =>
										setSelectedCompany(option.key)
									}
								/>
							</Item>
							<Item name="addRowButton" />
						</Toolbar>
					</DataGrid>
				</Container>
			</section>
		</div>
	);

	function UserPicker(params) {
		return (
			<>
				{params.row.isNewRow ? (
					<PeoplePicker
						userType={UserType.user}
						userFilters="userType eq 'Member' and accountEnabled eq true"
						selectionMode="single"
						selectionChanged={(e) => {
							params.data.id = e.detail[0]?.id;
							params.data.displayName = e.detail[0]?.displayName;
						}}
					/>
				) : (
					<PeoplePicker defaultSelectedUserIds={[params.key]} disabled={true} />
				)}
			</>
		);
	}
}
