import { getLocalApiAccessToken } from '../authorize/AuthorizeService';
import { getUserProfile, getUserPhoto } from './users';
import { CacheService } from 'utilities/CacheService'

const schema = {
    name: 'role',
    stores: {
        getAllRoles: 'getMyHeaderMenu',
        getRoles: 'getRoles',
    },
    version: 1
}

export async function getMyRole() {
    try {
        const token = await getLocalApiAccessToken();
        const response = await fetch(`api/me/role`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        return data;
    }
    catch (error) {
        console.error(error);
    }
}

export async function getAllRoles() {
    try {
        const cacheKey = `${schema.stores.getAllRoles}`;
        const cache = CacheService.getCache(schema, schema.stores.getAllRoles)
        const result = await cache.getValue(cacheKey);
        if (result && CacheService.config.defaultInvalidationPeriod > Date.now() - result.timeCached) {
            return result.results.map(c => JSON.parse(c));
        }
        const token = await getLocalApiAccessToken();
        const response = await fetch(`api/role`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        if (Array.isArray(data)) {
            const item = { results: null };
            item.results = data.map(c => JSON.stringify(c));
            cache.putValue(cacheKey, item);
        }
        return data;
    }
    catch (error) {
        console.error(error);
    }
}

export async function getRoles(companyCode) {
    try {
        const cacheKey = `${schema.stores.getRoles}:${companyCode}`;
        const cache = CacheService.getCache(schema, schema.stores.getRoles)
        const result = await cache.getValue(cacheKey);
        if (result && CacheService.config.defaultInvalidationPeriod > Date.now() - result.timeCached) {
            return result.results.map(c => JSON.parse(c));
        }
        const token = await getLocalApiAccessToken();
        const response = await fetch(`api/company/${encodeURIComponent(companyCode)}/roles`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        if (Array.isArray(data)) {
            const item = { results: null };
            item.results = data.map(c => JSON.stringify(c));
            cache.putValue(cacheKey, item);
        }
        return data;
    }
    catch (error) {
        console.error(error);
    }
}

export async function getRoleAssignedUsers(roleId) {
    try {
        if (roleId) {
            const token = await getLocalApiAccessToken();
            const response = await fetch(`api/role/${encodeURIComponent(roleId)}/users`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            const data = await response.json();
            return data;
        }
    }
    catch (error) {
        console.error(error);
    }
}

export async function getRoleAssignedUsersWithGraphApi(roleId) {
    try {
        const users = await getRoleAssignedUsers(roleId);
        if (Array.isArray(users)) {
            const response = await Promise.all(users.map(async (item) => {
                const profile = await getUserProfile(item.id);
                const photo = await getUserPhoto(item.id);
                return {
                    ...item,
                    ...profile,
                    imageUrl: photo
                };
            }));
            return response;
        }
    }
    catch (error) {
        console.error(error);
    }
}

export async function assigneUserToRole(values, roleId) {
    try {
        if (roleId) {
            const token = await getLocalApiAccessToken();
            await fetch(`api/role/${encodeURIComponent(roleId)}/users`, {
                method: 'post',
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                body: JSON.stringify(values)
            });
        }
    }
    catch (error) {
        console.error(error);
    }
}

export async function removeUserFromRole(key, roleId) {
    const token = await getLocalApiAccessToken();
    await fetch(`api/role/${encodeURIComponent(roleId)}/users/${encodeURIComponent(key)}`, {
        method: 'delete',
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    });
}