import React, { useRef } from "react";
import { Stack, DefaultButton, Link } from "@fluentui/react";
import HtmlEditor, {
  Toolbar,
  MediaResizing,
  Item,
  Mention,
} from "devextreme-react/html-editor";
import CustomStore from "devextreme/data/custom_store";
import { addDocComment, editDocComment } from "api/document";
import { getUsersForMentions, getUserWithGraphApi } from "api/users";
import { Persona, PersonaSize } from "@fluentui/react/lib/Persona";
import './editor.scss'

export default function Editor(props) {
  const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
  const fontValues = [
    "Malgun Gothic",
    "Gulim",
    "Segoe UI",
    "Tahoma",
    "Arial",
    "sans-serif",
  ];
  const headerValues = [false, 1, 2, 3, 4, 5];

  const { commentData, showEditor, isEdit, update } = props;
  const htmlEditor = useRef(null);

  async function onSubmit() {
    if (isEdit) {
      await onEditSubmit();
    } else {
      await onAddSubmit();
    }
    await update();
    showEditor(false);
  }

  async function onEditSubmit() {
    commentData.content = htmlEditor.current.instance.option("value");
    await editDocComment(commentData.documentId, { ...commentData });
  }

  async function onAddSubmit() {
    var data = {
      content: htmlEditor.current.instance.option("value"),
      documentId: commentData.documentId,
      parentId: commentData.id,
    };
    await addDocComment(data.documentId, data);
  }

  function defaultValue() {
    return isEdit ? commentData?.content : "";
  }

  const store = new CustomStore({
    key: "id",
    byKey: (key) => getUserWithGraphApi(key),
    load: (loadOptions) => getUsersForMentions(loadOptions),
  });

  const renderListItem = (data) => {
    return (
      <Persona
        {...data}
        text={data.displayName}
        secondaryText={data.jobTitle}
        size={PersonaSize.size40}
      />
    );
  };

  return (
    <>
      <Stack>
        <HtmlEditor
          ref={htmlEditor}
          defaultValue={defaultValue()}
          height={300}
          style={{ backgroundColor: "white" }}
          valueType="html"
        >
          <MediaResizing enabled={true} />
          <Toolbar multiline={false}>
            <Item name="undo" />
            <Item name="redo" />
            <Item name="separator" />
            <Item name="size" acceptedValues={sizeValues} />
            <Item name="font" acceptedValues={fontValues} />
            <Item name="separator" />
            <Item name="bold" />
            <Item name="italic" />
            <Item name="strike" />
            <Item name="underline" />
            <Item name="separator" />
            <Item name="alignLeft" />
            <Item name="alignCenter" />
            <Item name="alignRight" />
            <Item name="alignJustify" />
            <Item name="separator" />
            <Item name="orderedList" />
            <Item name="bulletList" />
            <Item name="separator" />
            <Item name="header" acceptedValues={headerValues} />
            <Item name="separator" />
            <Item name="color" />
            <Item name="background" />
            <Item name="separator" />
            <Item name="link" />
            <Item name="image" />
            <Item name="separator" />
            <Item name="clear" />
            <Item name="codeBlock" />
            <Item name="blockquote" />
            <Item name="separator" />
            <Item name="insertTable" />
            <Item name="deleteTable" />
            <Item name="insertRowAbove" />
            <Item name="insertRowBelow" />
            <Item name="deleteRow" />
            <Item name="insertColumnLeft" />
            <Item name="insertColumnRight" />
            <Item name="deleteColumn" />
          </Toolbar>
          <Mention
            dataSource={store}
            displayExpr="displayName"
            valueExpr="id"
            itemRender={renderListItem}
          />
        </HtmlEditor>
      </Stack>
      <Stack
        horizontal
        tokens={{ childrenGap: "s1" }}
        style={{ paddingBottom: "10px" }}
      >
        <Stack wrap>
          <DefaultButton text="Save" onClick={() => onSubmit()} />
        </Stack>
        <Stack verticalAlign="center">
          <Link onClick={() => showEditor(false)} underline>
            Cancel
          </Link>
        </Stack>
      </Stack>
    </>
  );
}
