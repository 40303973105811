import { getLocalApiAccessToken } from '../authorize/AuthorizeService';

export async function checkMyPermission(path) {
    try {
        const token = await getLocalApiAccessToken();
        const response = await fetch(`api/me/check-permission?path=${path}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        return data;
    }
    catch (error) {
        console.error(error);
    }
}
