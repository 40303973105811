import React, { useRef, useState, useEffect } from "react";
import Chart, {
	CommonSeriesSettings,
	Tooltip,
	ValueAxis,
	Legend,
	SeriesTemplate,
	Label,
} from "devextreme-react/chart";
import { Card, Container, Row, Col } from "react-bootstrap";
import ContentHeader from "components/bootstrap/content-header/content-header";
import {
	getOrganizaion,
	getJiraIssueDataForDashbaord,
	getConfluenceActionItemsForDashbaord,
	getConfluenceContentsForDashboard,
} from "api/data";
import { colorScheme, convertReferenceDateToString } from "utilities/common";
import CardLoading from "components/bootstrap/card-loading/card-loading";

export default function Dashboard() {
	const mainChart = useRef(null);
	const leftChart = useRef(null);
	const rightChart = useRef(null);
	const [mainChartLoading, setMainChartLoading] = useState(true);
	const [leftChartLoading, setLeftChartLoading] = useState(true);
	const [rightChartLoading, setRightChartLoading] = useState(true);
	const [vtsData, setVTSData] = useState(null);
	const [actionItemData, setActionItemData] = useState(null);
	const [contentData, setContentData] = useState(null);
	const [date, setDate] = useState(null);

	useEffect(() => {
		if (date) {
			(async function () {
				var result = await getJiraIssueDataForDashbaord(date.toISOString());
				setVTSData(result.sort((a, b) => a.idx - b.idx));
				setMainChartLoading(false);
			})();
			(async function () {
				var result = await getConfluenceActionItemsForDashbaord(
					date.toISOString()
				);
				setActionItemData(result.sort((a, b) => a.idx - b.idx));
				setLeftChartLoading(false);
			})();
			(async function () {
				var result = await getConfluenceContentsForDashboard(
					date.toISOString()
				);
				setContentData(result.sort((a, b) => a.idx - b.idx));
				setRightChartLoading(false);
			})();
		} else {
			(async function () {
				var result = await getOrganizaion();
				setDate(new Date(result.loadDate));
			})();
		}
	}, [date]);

	function reRenderChart() {
		mainChart.current?.instance.render();
		leftChart.current?.instance.render();
		rightChart.current?.instance.render();
	}

	return (
		<div
			className="content-wrapper"
			onTransitionEnd={() => {
				reRenderChart();
			}}
		>
			<ContentHeader pageName={"Dashboard"} disableShare={true} />
			<section className="content">
				<Container fluid>
					<Row>
						<Col md={12}>
							<Card className="card-outline card-primary">
								<CardLoading visible={mainChartLoading} />
								<Card.Header className="text-center">
									<h5>
										<b>
											{`VTS 과제 현황 ${convertReferenceDateToString(date)}`}
										</b>
									</h5>
								</Card.Header>
								<Card.Body>
									<DashboardChartMemo
										dataSource={vtsData}
										palette={colorScheme}
										ref={mainChart}
									/>
								</Card.Body>
							</Card>
						</Col>
					</Row>
					<Row>
						<Col md={4}>
							<Card className="card-outline card-warning">
								<CardLoading visible={leftChartLoading} />
								<Card.Header className="text-center">
									<h5>
										<b>
											{`VKS Action item ${convertReferenceDateToString(date)}`}
										</b>
									</h5>
								</Card.Header>
								<Card.Body>
									<DashboardChartMemo
										dataSource={actionItemData}
										palette={colorScheme}
										ref={leftChart}
									/>
								</Card.Body>
							</Card>
						</Col>
						<Col md={8}>
							<Card className="card-outline card-info">
								<CardLoading visible={rightChartLoading} />
								<Card.Header className="text-center">
									<h5>
										<b>
											{`최근 30일간 등록된 제안 ${convertReferenceDateToString(
												date
											)}`}
										</b>
									</h5>
								</Card.Header>
								<Card.Body>
									<DashboardChartMemo
										dataSource={contentData}
										palette={colorScheme}
										ref={rightChart}
									/>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</section>
		</div>
	);
}

function DashboardChart(props, ref) {
	return (
		<Chart {...props} ref={ref}>
			<CommonSeriesSettings
				valueField="count"
				argumentField="category"
				type="bar"
				ignoreEmptyPoints={true}
			>
				<Label visible={true} customizeText={(arg) => `${arg.valueText} 건`} />
			</CommonSeriesSettings>
			<SeriesTemplate nameField="category" />
			<ValueAxis
				visible={true}
				autoBreaksEnabled={true}
				maxAutoBreakCount={1}
			/>
			<Tooltip enabled={true} />
			<Legend visible={false} />
		</Chart>
	);
}
const DashboardChartMemo = React.memo(React.forwardRef(DashboardChart));
