import React, { useEffect, useRef, useMemo, useState } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import ContentHeader from "components/bootstrap/content-header/content-header";
import {
	colorScheme,
	groupBy,
	roundToTwo,
	convertReferenceDateToString,
} from "utilities/common";
import { getApprovalDuration7Days, getOrganizaion } from "api/data";
import Chart, {
	CommonSeriesSettings,
	Tooltip,
	ValueAxis,
	Legend,
	SeriesTemplate,
	ConstantLine,
	Label,
	CommonAxisSettings,
} from "devextreme-react/chart";
import DataGrid, { Column } from "devextreme-react/data-grid";
import { addSavedData } from "api/document";
import CardLoading from "components/bootstrap/card-loading/card-loading";

const defaultSelected = {
	groupCode: "VN",
	companyCode: "VN",
};

export default function Duration(props) {
	const barChart = useRef(null);
	const [select, setSelect] = useState(defaultSelected);
	const [date, setDate] = useState(null);
	const [groups, setGroups] = useState([]);
	const [loading, setLoading] = useState(true);
	const [rawData, setRawData] = useState(null);
	const [filter, setFilter] = useState(null);
	const { savedData, docId } = props;

	useEffect(() => {
		if (savedData) {
			setSelect(savedData.select);
			setDate(new Date(savedData.date));
			setRawData(savedData.rawData);
			setGroups(savedData.groups);
			setLoading(false);
		}
		if (!savedData) {
			(async function () {
				var result = await getOrganizaion();
				setDate(new Date(result.loadDate));
				setGroups(result.groups);
				var selectedGroup = result.groups.find(
					(c) =>
						c.companyCode === defaultSelected.companyCode &&
						c.groupCode === defaultSelected.groupCode
				);
				selectedGroup.parent = null;
				setSelect(selectedGroup);
				var data = await getApprovalDuration7Days(
					new Date(result.loadDate).toISOString()
				);
				setRawData(
					data.map((c) => {
						var startDate = new Date(c.startDate);
						var endDate = new Date(c.endDate);
						return {
							...c,
							startDate: startDate,
							endDate: endDate,
							duration: Math.floor((endDate - startDate) / 1000 / 60),
						};
					})
				);
				setLoading(false);
			})();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	async function saveDocument() {
		select.parent = null;
		var data = {
			pageName: "Duration",
			title: `전자결재 => 최근 7일 결재 소요시간  ${convertReferenceDateToString(
				date
			)}`,
			data: {
				select: select,
				date: date,
				rawData: chartData.flatMap((c) => c.data),
				groups: groups,
			},
		};
		return await addSavedData(data);
	}

	useEffect(() => {
		var result = [];
		if (select.children) {
			select.children.forEach((item) => {
				if (!Object.keys(item).some((c) => c === "checked")) {
					item.checked = true;
				}
			});
			Array.prototype.push.apply(
				result,
				select.children.sort((a, b) => a.displayOrder - b.displayOrder)
			);
		}
		if (select.members) {
			select.members.forEach((item) => {
				item.companyCode = item.employeeId;
				item.groupCode = item.employeeId;
				item.groupName = item.displayName;
				item.childrenMembers = [item.employeeId];
				if (!Object.keys(item).some((c) => c === "checked")) {
					item.checked = true;
				}
			});
			Array.prototype.push.apply(
				result,
				select.members.sort((a, b) => a.displayOrder - b.displayOrder)
			);
		}
		if (
			savedData &&
			savedData.select.companyCode === select.companyCode &&
			savedData.select.groupCode === select.groupCode
		) {
			result = savedData.select.children
				.filter((d) => d.checked === true)
				.sort((a, b) => a.displayOrder - b.displayOrder);
			Array.prototype.push.apply(
				result,
				savedData.select.members
					.filter((d) => d.checked === true)
					.sort((a, b) => a.displayOrder - b.displayOrder)
			);
		}
		setFilter(result);
	}, [select, savedData]);

	var chartData = useMemo(() => {
		if (!rawData) {
			return [];
		}
		return filter
			.filter((c) => c.checked === true)
			.map((c) => {
				var filterd = rawData.filter((d) =>
					c.childrenMembers.some((e) => e === d.dataOwnerInfo.employeeId)
				);
				
				return {
					idx: c.displayOrder,
					category: c.groupName,
					data: filterd,
					durationSum: filterd.reduce((a, b) => a + b.duration, 0),
					durationAvgHour: roundToTwo(filterd.reduce((a, b) => a + b.duration, 0) / filterd.length / 60)
					
				};
			});
	}, [rawData, filter]);

	var averageDuration = useMemo(() => {
		var result = 0;
		if (chartData) {
			var data = chartData.flatMap((c) => c.data);
			result = roundToTwo(data.reduce((a, b) => a + b.duration, 0) / data.length / 60);
		}
		return result;
	}, [chartData]);

	var gridData = useMemo(() => {
		var result = [];
		if (chartData) {
			var temp = chartData
				.flatMap((c) => c.data)
				.map((c) => {
					var group = filter.find((d) =>
						d.childrenMembers.some((e) => e === c.dataOwnerInfo.employeeId)
					);
					return {
						...c,
						employeeId: c.dataOwnerInfo.employeeId,
						displayName: c.dataOwnerInfo.displayName,
						groupName: group?.groupName,
					};
				});
			result = groupBy(temp, "employeeId").map((c) => {
				var sum = c.value.reduce((a, b) => a + b.duration, 0);
				var count = c.value.length;
				return {
					...c,
					employeeId: c.key,
					displayName: c.value.find((d) => true).displayName,
					groupName: c.value.find((d) => true).groupName,
					count: count,
					durationSum: sum,
					durationAvg: sum / count,
				};
			});
		}
		return result;
	}, [chartData, filter]);

	function reRenderChart() {
		if (barChart) barChart.current.instance.render();
	}

	function updateCheckedFilter(event) {
		if (filter?.length > 0) {
			var group = event.target.dataset;
			filter.find(
				(c) =>
					c.companyCode === group.companyCode && c.groupCode === group.groupCode
			).checked = event.target.checked;
			setFilter([...filter]);
		}
	}

	function updateAllChecked(event) {
		filter?.forEach((c) => {
			c.checked = event.target.checked;
		});
		setFilter([...filter]);
	}

	function onPointClick(e) {
		var selectedGroup = filter.find(
			(c) => c.groupName === e.target.originalArgument
		);

		var group = groups.find(
			(c) =>
				c.companyCode === selectedGroup.companyCode &&
				c.groupCode === selectedGroup.groupCode
		);
		if (group?.children?.length > 0 || group?.members?.length > 0) {
			setSelect({
				...select,
				...group,
			});
		}
	}

	function onButtonClick() {
		if (select.parent) {
			var group = groups.find(
				(c) =>
					c.companyCode === select.parent.companyCode &&
					c.groupCode === select.parent.groupCode
			);
			setSelect(group);
		}
	}

	const hideButton = useMemo(() => {
		if (!select.parent) {
			return "d-none";
		}
		return "";
	}, [select]);

	return (
		<div
			className="content-wrapper"
			onTransitionEnd={() => {
				reRenderChart();
			}}
		>
			<ContentHeader
				pageName={"결재소요시간"}
				saveDocument={saveDocument}
				docId={docId}
			/>

			<section className="content">
				<Container fluid>
					<Row>
						<Col md={12}>
							<Card className="card-outline card-info">
								<Card.Body>
									<Form.Check
										inline
										label={"전체선택"}
										defaultChecked={!filter?.some((c) => !c.checked)}
										onChange={updateAllChecked}
									/>
									{filter?.map((item) => {
										return (
											<Form.Check
												key={`${item.companyCode}|${item.groupCode}`}
												id={`${item.companyCode}|${item.groupCode}`}
												data-group-code={item.groupCode}
												data-company-code={item.companyCode}
												label={item.groupName}
												inline
												checked={item.checked}
												onChange={updateCheckedFilter}
											/>
										);
									})}
								</Card.Body>
							</Card>
						</Col>
					</Row>
					<Row>
						<Col md={12}>
							<Card>
								<CardLoading visible={loading} />
								<Card.Header className="text-center">
									<h5>
										<b>{`최근 7일 결재 소요시간 ${convertReferenceDateToString(
											date
										)}`}</b>
									</h5>
								</Card.Header>
								<Card.Body>
									<Chart
										palette={colorScheme}
										dataSource={chartData}
										ref={barChart}
										onPointClick={onPointClick}
									>
										<CommonSeriesSettings
											valueField="durationAvgHour"
											argumentField="category"
											type="bar"
											ignoreEmptyPoints={true}
										>
											<Label
												visible={true}
												customizeText={(arg) => `${arg.valueText}시간`}
											/>
										</CommonSeriesSettings>
										<CommonAxisSettings>
											<Label overlappingBehavior={"stagger"} />
										</CommonAxisSettings>
										<SeriesTemplate nameField="category" />
										<ValueAxis
											visible={true}
											autoBreaksEnabled={true}
											maxAutoBreakCount={1}
										>
											<ConstantLine
												width={3}
												value={averageDuration}
												color="#ff7c7c"
												dashStyle="dash"
											>
												<Label text={`평균 ${averageDuration}시간`} />
											</ConstantLine>
										</ValueAxis>
										<Tooltip enabled={true} />
										<Legend visible={false} />
									</Chart>
									<Button
										variant="outline-secondary"
										className={`bg-white ${hideButton}`}
										style={{
											position: "absolute",
											top: "20px",
											left: "20px",
										}}
										onClick={onButtonClick}
									>
										<i className="fas fa-angle-left"></i> Back
									</Button>
								</Card.Body>
							</Card>
						</Col>
					</Row>
					<Row>
						<Col md={12}>
							<Card>
								<CardLoading visible={loading} />
								<Card.Body>
									<DataGrid dataSource={gridData}>
										<Column dataField="groupName" caption="소속" />
										<Column dataField="displayName" caption="결재자" />
										<Column
											dataField="count"
											caption="결재건수 (건)"
											customizeText={(data) => `${data.value} 건`}
										/>
										<Column
											dataField="durationSum"
											caption="총 소요시간 (분)"
											customizeText={(data) => `${data.value} 분`}
										/>
										<Column
											dataField="durationAvg"
											caption="평균 소요시간 (분)"
											alignment="right"
											customizeText={(data) => `${roundToTwo(data.value)} 분`}
										/>
										<Column
											name="durationAvgHour"
											dataField="durationAvg"
											caption="평균 소요시간 (시간)"
											alignment="right"
											customizeText={(data) =>
												`${roundToTwo(data.value / 60)} 시간`
											}
										/>
									</DataGrid>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</section>
		</div>
	);
}
