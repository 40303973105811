import React, { useState, useCallback, useRef } from "react";
import {
	Person,
	ViewType,
	PeoplePicker,
	UserType,
	PersonCardInteraction,
} from "@microsoft/mgt-react";
import {
	getDocShared,
	getDocOwner,
	addDocShared,
	deleteDocShared,
} from "api/document";
import { Panel } from "@fluentui/react/lib/Panel";
import { useBoolean } from "@fluentui/react-hooks";
import { Stack } from "@fluentui/react";
import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { IconButton } from "@fluentui/react/lib/Button";
import { Label } from "@fluentui/react/lib/Label";
import { Col, Row } from "react-bootstrap";
import style from "./shared-facepile.module.css";

export default function SharedFacepile(props) {
	const { docId } = props;
	const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
		useBoolean(false);
	const [tempSharedIds, setTempSharedIds] = useState([]);
	const sharePeoplePicker = useRef(null);

	const [ownerId, setOwnerId] = useState(null);
	const [sharedIds, setSharedIds] = useState([]);

	useState(() => {
		if (docId) {
			(async function () {
				const user = await getDocOwner(docId);
				const shared = await getDocShared(docId);
				setOwnerId(user.id);
				setSharedIds(shared.map((c) => c.userId));
				await updateSharedList();
			})();
		}
	}, [docId]);

	const updateSharedList = useCallback(async () => {
		const shared = await getDocShared(docId);
		setSharedIds(shared.map((c) => c.userId));
		setTempSharedIds(shared.map((c) => c.userId));
	}, [docId]);

	const savePanel = useCallback(async () => {
		await Promise.all(
			tempSharedIds
				.filter((c) => !sharedIds.some((d) => d === c))
				.map(async (item) => {
					await addDocShared(docId, {
						id: item,
					});
				})
		);
		await Promise.all(
			sharedIds
				.filter((c) => !tempSharedIds.some((d) => d === c))
				.map(async (item) => {
					await deleteDocShared(docId, { userId: item });
				})
		);
		await updateSharedList();
		dismissPanel();
	}, [dismissPanel, updateSharedList, tempSharedIds, sharedIds, docId]);

	const cancelPanel = useCallback(() => {
		dismissPanel();
		setTempSharedIds([...sharedIds]);
	}, [dismissPanel, sharedIds]);

	const addTempShared = useCallback(
		(e) => {
			if (e.detail.length !== 0) {
				var isExists = tempSharedIds.some((item) => item === e.detail[0].id);
				if (!isExists) {
					setTempSharedIds([...tempSharedIds, e.detail[0].id]);
				}
				sharePeoplePicker.current.selectedPeople = [];
			}
		},
		[tempSharedIds]
	);

	const onRenderFooterContent = useCallback(
		() => (
			<>
				<PrimaryButton
					onClick={savePanel}
					styles={{ root: { marginRight: 8 } }}
					text={"Save"}
				/>
				<DefaultButton onClick={cancelPanel} text={"Cancel"} />
			</>
		),
		[savePanel, cancelPanel]
	);

	return (
		<div className={"float-right"}>
			<Row>
				<Col className="d-inline-flex align-items-bottom">
					{sharedIds.map((item) => (
						<Person
							key={item}
							className={style.avatarOutline}
							userId={item}
							view={ViewType.image}
							personCardInteraction={PersonCardInteraction.hover}
						/>
					))}
					<Person
						className={`${style.avatarOutline} ${style.moreButton}`}
						view={ViewType.image}
						personDetails={{
							personImage: `${process.env.PUBLIC_URL}/ellipsis.svg`,
						}}
						onClick={openPanel}
					/>
				</Col>
			</Row>

			<Panel
				headerText="공유"
				isOpen={isOpen}
				onDismiss={dismissPanel}
				closeButtonAriaLabel="Close"
				onRenderFooterContent={onRenderFooterContent}
				isFooterAtBottom={true}
			>
				<Stack tokens={{ childrenGap: 30 }}>
					<Stack tokens={{ childrenGap: 5 }}>
						<Label>Owner</Label>
						<Stack
							horizontal
							horizontalAlign="space-between"
							verticalAlign={"center"}
							className={"share-persona"}
						>
							<Person
								userId={ownerId}
								view={ViewType.twolines}
								line1Property="displayName"
								line2Property="jobTitle"
							/>
						</Stack>
					</Stack>
					<Stack tokens={{ childrenGap: 5 }}>
						<Stack
							horizontal
							horizontalAlign="space-between"
							verticalAlign={"center"}
						>
							<Label>Shared</Label>
						</Stack>
						<Stack>
							<PeoplePicker
								ref={sharePeoplePicker}
								userType={UserType.user}
								userFilters="userType eq 'Member' and accountEnabled eq true"
								selectionMode="single"
								selectionChanged={addTempShared}
							/>
						</Stack>
						{tempSharedIds.map((item) => {
							return (
								<Stack
									key={item}
									horizontal
									horizontalAlign="space-between"
									verticalAlign={"center"}
									className={"share-persona"}
								>
									<Person
										userId={item}
										view={ViewType.twolines}
										line1Property="displayName"
										line2Property="jobTitle"
									/>
									<IconButton
										className={"remove-button"}
										iconProps={{ iconName: "Cancel" }}
										onClick={() =>
											setTempSharedIds([
												...tempSharedIds.filter((c) => c !== item),
											])
										}
									/>
								</Stack>
							);
						})}
					</Stack>
				</Stack>
			</Panel>
		</div>
	);
}
