import { getLocalApiAccessToken } from "../authorize/AuthorizeService";
import { CacheService } from "utilities/CacheService";
import {
	getCategorys,
} from "utilities/common";

const schema = {
	name: "data",
	stores: {
		jiraIssueData: "jiraIssueData",
		confluenceActionItem: "confluenceActionItem",
		confluenceContent: "confluenceContent",
		sharepointStorage: "sharepointStorage",
		messageTrace: "messageTrace",
		approvalDuration: "approvalDuration",
	},
	version: 4,
};

export async function getOrganizaion() {
	try {
		const token = await getLocalApiAccessToken();
		const response = await fetch(
			`api/data/organization`,
			{
				headers: !token ? {} : { Authorization: `Bearer ${token}` },
			}
		);
		const data = await response.json();
		return data;
	} catch (error) {
		console.error(error);
	}
}

export async function getJiraIssueData(category, date) {
	try {
		const cacheKey = `${schema.stores.jiraIssueData}:${category}:${date}`;
		const cache = CacheService.getCache(schema, schema.stores.jiraIssueData);
		const result = await cache.getValue(cacheKey);
		if (
			result &&
			CacheService.config.defaultInvalidationPeriod >
				Date.now() - result.timeCached
		) {
			return JSON.parse(result.results);
		}
		const token = await getLocalApiAccessToken();
		const response = await fetch(
			`api/data/vts/issue?category=${category}&date=${date}`,
			{
				headers: !token ? {} : { Authorization: `Bearer ${token}` },
			}
		);
		const data = await response.json();
		if (data) {
			const item = { results: null };
			item.results = JSON.stringify(data);
			cache.putValue(cacheKey, item);
		}
		return data;
	} catch (error) {
		console.error(error);
	}
}

export async function getApprovalDuration(date) {
	try {
		const cacheKey = `${schema.stores.approvalDuration}:${date}`;
		const cache = CacheService.getCache(schema, schema.stores.approvalDuration);
		const result = await cache.getValue(cacheKey);
		if (
			result &&
			CacheService.config.defaultInvalidationPeriod >
				Date.now() - result.timeCached
		) {
			return JSON.parse(result.results);
		}
		const token = await getLocalApiAccessToken();
		const response = await fetch(`api/data/approval/duration?date=${date}`, {
			headers: !token ? {} : { Authorization: `Bearer ${token}` },
		});
		const data = await response.json();
		if (data) {
			const item = { results: null };
			item.results = JSON.stringify(data);
			cache.putValue(cacheKey, item);
		}
		return data;
	} catch (error) {
		console.error(error);
	}
}

export async function getApprovalDuration7Days(date) {
	try {
		var dateArray = [];
		for (var i = 0; i < 7; i++) {
			dateArray.push(
				new Date(new Date(date).setDate(new Date(date).getDate() - i))
			);
		}
		var result = [];
		await Promise.all(
			dateArray.map(async (item) => {
				var data = await getApprovalDuration(item.toISOString());
				result.push(...data);
			})
		);
		return result;
	} catch (error) {
		console.error(error);
	}
}

export async function getSharePointStorage(date) {
	try {
		const cacheKey = `${schema.stores.sharepointStorage}:${date}`;
		const cache = CacheService.getCache(
			schema,
			schema.stores.sharepointStorage
		);
		const result = await cache.getValue(cacheKey);
		if (
			result &&
			CacheService.config.defaultInvalidationPeriod >
				Date.now() - result.timeCached
		) {
			return JSON.parse(result.results);
		}
		const token = await getLocalApiAccessToken();
		const response = await fetch(`api/data/m365/storage?date=${date}`, {
			headers: !token ? {} : { Authorization: `Bearer ${token}` },
		});
		const data = await response.json();
		if (data) {
			const item = { results: null };
			item.results = JSON.stringify(data);
			cache.putValue(cacheKey, item);
		}
		return data;
	} catch (error) {
		console.error(error);
	}
}

export async function getSharePointStorage7Days(date) {
	try {
		var dateArray = [];
		for (var i = 0; i < 7; i++) {
			dateArray.push(
				new Date(new Date(date).setDate(new Date(date).getDate() - i))
			);
		}
		var result = [];
		await Promise.all(
			dateArray.map(async (item) => {
				var data = await getSharePointStorage(item.toISOString());
				result.push(...data);
			})
		);
		return result;
	} catch (error) {
		console.error(error);
	}
}

export async function getMessageTrace(date) {
	try {
		const cacheKey = `${schema.stores.messageTrace}:${date}`;
		const cache = CacheService.getCache(schema, schema.stores.messageTrace);
		const result = await cache.getValue(cacheKey);
		if (
			result &&
			CacheService.config.defaultInvalidationPeriod >
				Date.now() - result.timeCached
		) {
			return JSON.parse(result.results);
		}
		const token = await getLocalApiAccessToken();
		const response = await fetch(`api/data/m365/message-trace?date=${date}`, {
			headers: !token ? {} : { Authorization: `Bearer ${token}` },
		});
		const data = await response.json();
		if (data) {
			const item = { results: null };
			item.results = JSON.stringify(data);
			cache.putValue(cacheKey, item);
		}
		return data;
	} catch (error) {
		console.error(error);
	}
}

export async function getMessageTrace7Days(date) {
	try {
		var dateArray = [];
		for (var i = 0; i < 7; i++) {
			dateArray.push(
				new Date(new Date(date).setDate(new Date(date).getDate() - i))
			);
		}
		var result = [];
		await Promise.all(
			dateArray.map(async (item) => {
				var data = await getMessageTrace(item.toISOString());
				result.push(...data);
			})
		);
		return result;
	} catch (error) {
		console.error(error);
	}
}

export async function getConfluenceActionItems(category, date) {
	try {
		const cacheKey = `${schema.stores.confluenceActionItem}:${category}:${date}`;
		const cache = CacheService.getCache(
			schema,
			schema.stores.confluenceActionItem
		);
		const result = await cache.getValue(cacheKey);
		if (
			result &&
			CacheService.config.defaultInvalidationPeriod >
				Date.now() - result.timeCached
		) {
			return JSON.parse(result.results);
		}
		const token = await getLocalApiAccessToken();
		const response = await fetch(
			`api/data/vks/action-item?category=${category}&date=${date}`,
			{
				headers: !token ? {} : { Authorization: `Bearer ${token}` },
			}
		);
		const data = await response.json();
		if (data) {
			const item = { results: null };
			item.results = JSON.stringify(data);
			cache.putValue(cacheKey, item);
		}
		return data;
	} catch (error) {
		console.error(error);
	}
}

export async function getConfluenceActionItemsOneDay(date) {
	try {
		const category = {
			Upcoming: "마감이 임박한 Action Item",
			Delayed: "지연중인 Action Item",
		};

		var result = [];
		await Promise.all(
			Object.keys(category).map(async (item) => {
				var data = await getConfluenceActionItems(item, date);
				result.push(...data);
			})
		);
		return result;
	} catch (error) {
		console.error(error);
	}
}

export async function getConfluenceActionItemsForDashbaord(date) {
	try {
		const category = {
			Upcoming: "마감이 임박한 Action Item",
			Delayed: "지연중인 Action Item",
		};
		var result = await Promise.all(
			Object.keys(category).map(async (item, idx) => {
				var filterd = await getConfluenceActionItems(item, date);
				return {
					idx: idx,
					category: category[item],
					data: filterd,
					count: filterd.length,
				};
			})
		);
		return result;
	} catch (error) {
		console.error(error);
	}
}

export async function getConfluenceContents(date) {
	try {
		const cacheKey = `${schema.stores.confluenceContent}:${date}`;
		const cache = CacheService.getCache(
			schema,
			schema.stores.confluenceContent
		);
		const result = await cache.getValue(cacheKey);
		if (
			result &&
			CacheService.config.defaultInvalidationPeriod >
				Date.now() - result.timeCached
		) {
			return JSON.parse(result.results);
		}
		const token = await getLocalApiAccessToken();
		const response = await fetch(`api/data/vks/content?date=${date}`, {
			headers: !token ? {} : { Authorization: `Bearer ${token}` },
		});
		const data = await response.json();
		if (data) {
			const item = { results: null };
			item.results = JSON.stringify(data);
			cache.putValue(cacheKey, item);
		}
		return data;
	} catch (error) {
		console.error(error);
	}
}

export async function getConfluenceContents30Days(date) {
	try {
		var dateArray = [];
		for (var i = 0; i < 30; i++) {
			dateArray.push(
				new Date(new Date(date).setDate(new Date(date).getDate() - i))
			);
		}
		var result = [];
		await Promise.all(
			dateArray.map(async (item) => {
				var data = await getConfluenceContents(item.toISOString());
				result.push(...data);
			})
		);
		return result;
	} catch (error) {
		console.error(error);
	}
}

export async function getConfluenceContentsForDashboard(date) {
	try {
		var data = await getConfluenceContents30Days(date);
		var category = getCategorys(data).filter(c => c.depth === 1);
		var result = category.map((item) => {
			var filterd = data.filter((c) =>
				c.dataCategoryInfo.some((d) => d.categoryCode === item.categoryCode)
			);
			return {
				idx: item.categoryCode,
				category: item.categoryName,
				data: filterd,
				count: filterd.length,
			};
		});
		return result;
	} catch (error) {
		console.error(error);
	}
}

export async function getJiraIssueDataOneDay(date) {
	try {
		const category = {
			New: "최근 7일간 신규 개설 과제",
			Delayed: "지연된 과제",
			Idle: "방치된 과제",
			NotClosed: "미결 과제",
			Upcoming: "마감이 임박한 과제",
		};
		var result = [];

		await Promise.all(
			Object.keys(category).map(async (item) => {
				var data = await getJiraIssueData(item, date);
				result.push(...data);
			})
		);
		return result;
	} catch (error) {
		console.error(error);
	}
}

export async function getJiraIssueDataForDashbaord(date) {
	try {
		const category = {
			New: "최근 7일간 신규 개설 과제",
			Delayed: "지연된 과제",
			Idle: "방치된 과제",
			NotClosed: "미결 과제",
			Upcoming: "마감이 임박한 과제",
		};
		var result = await Promise.all(
			Object.keys(category).map(async (item, idx) => {
				var filterd = await getJiraIssueData(item, date);
				return {
					idx: idx,
					category: category[item],
					data: filterd,
					count: filterd.length,
				};
			})
		);

		return result;
	} catch (error) {
		console.error(error);
	}
}
