import React from "react";
import { Nav } from "react-bootstrap";
import SidebarMenuItem from "./sidebar-menu-item";

export default function SidebarMenu({ menus }) {
	return (
		<nav className="mt-2">
			<Nav as="ul" className={`nav nav-pills nav-sidebar flex-column nav-flat`}>
				{Array.isArray(menus) ? (
					menus.map((item) => {
						return <SidebarMenuItem key={item.id} item={item} />;
					})
				) : (
					<></>
				)}
			</Nav>
		</nav>
	);
}
