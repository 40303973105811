import React from "react";
import TreeList, { Column, Selection } from "devextreme-react/tree-list";
import CustomStore from "devextreme/data/custom_store";
import { getAllNavMenus } from "api/menu";
import ContentHeader from "components/bootstrap/content-header/content-header";
import { Container } from "react-bootstrap";

export default function Permission() {
	const store = new CustomStore({
		key: "id",
		// byKey: (key) => getUserWithGraphApi(key),
		load: (loadOptions) => getAllNavMenus(),
		// insert: (values) => assigneUserToRole(values, selectedRole),
		// remove: (key) => removeUserFromRole(key, selectedRole)
		parentIdExpr: "parentId",
	});

	return (
		<div className="content-wrapper" onTransitionEnd={() => {}}>
			<ContentHeader pageName={"권한"} disableShare/>
			<section className="content">
				<Container fluid>
					<TreeList
						dataSource={store}
						showRowLines={true}
						showBorders={true}
						columnAutoWidth={true}
						onSelectionChanged={onSelectionChanged}
					>
						<Selection recursive={true} mode="multiple" />
						<Column dataField="id" visible={false} />
						<Column dataField="text" />
						<Column dataField="path" />
						<Column dataField="icon" />
						<Column dataField="order" defaultSortOrder="asc" />
						<Column dataField="isActive" dataType="boolean" />
					</TreeList>
				</Container>
			</section>
		</div>
	);

	function onSelectionChanged(e) {
	}
}
