import { getGraphApiAccessToken, getLocalApiAccessToken, getAccount } from '../authorize/AuthorizeService';
import { CacheService } from 'utilities/CacheService'
import { getMyRole } from './role';

const schema = {
    name: 'users',
    stores: {
        getMe: 'getMe',
        getMyProfile: 'getMyProfile',
        getMyPhoto: 'getMyPhoto',
        getUserProfile: 'getUserProfile',
        getUserPhoto: 'getUserPhoto',
        getUsers: 'getUsers',
        getUser: 'getUser',
        searchUsers: 'searchUsers'
    },
    version: 2
}

export async function getMe() {
    try {
        const account = getAccount();
        const cacheKey = `${schema.stores.getMe}:${account.localAccountId}`;
        const cache = CacheService.getCache(schema, schema.stores.getMe)
        const result = await cache.getValue(cacheKey);
        if (result && CacheService.config.defaultInvalidationPeriod > Date.now() - result.timeCached) {
            return JSON.parse(result.results);
        }
        const token = await getLocalApiAccessToken();
        const response = await fetch('api/me', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        if (data) {
            const item = { results: null };
            item.results = JSON.stringify(data);
            cache.putValue(cacheKey, item);
        }
        return data;
    }
    catch (error) {
        console.error(error);
    }
}

export async function getMyProfile() {
    try {
        const account = getAccount();
        const cacheKey = `${schema.stores.getMyProfile}:${account.localAccountId}`;
        const cache = CacheService.getCache(schema, schema.stores.getMyProfile)
        const result = await cache.getValue(cacheKey);
        if (result && CacheService.config.defaultInvalidationPeriod > Date.now() - result.timeCached) {
            return JSON.parse(result.results);
        }
        const token = await getGraphApiAccessToken();
        const response = await fetch('https://graph.microsoft.com/v1.0/me', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        if (data) {
            const item = { results: null };
            item.results = JSON.stringify(data);
            cache.putValue(cacheKey, item);
        }
        return data;
    }
    catch (error) {
        console.error(error);
    }
}

export async function getMyPhoto() {
    try {
        const account = getAccount();
        const cacheKey = `${schema.stores.getMyPhoto}:${account.localAccountId}`;
        const cache = CacheService.getCache(schema, schema.stores.getMyPhoto)
        const result = await cache.getValue(cacheKey);
        if (result && CacheService.config.defaultInvalidationPeriod > Date.now() - result.timeCached) {
            return JSON.parse(result.results);
        }
        const token = await getGraphApiAccessToken();
        const response = await fetch('https://graph.microsoft.com/v1.0/me/photo/$value', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        //const data = window.URL.createObjectURL(await response.blob());
        const data = await blobToBase64(await response.blob());
        if (data) {
            const item = { results: null };
            item.results = JSON.stringify(data);
            cache.putValue(cacheKey, item);
        }
        return data;
    }
    catch (error) {
        console.error(error);
    }
}


export async function getUserProfile(id) {
    try {
        const cacheKey = `${schema.stores.getUserProfile}:${id}`;
        const cache = CacheService.getCache(schema, schema.stores.getUserProfile)
        const result = await cache.getValue(cacheKey);
        if (result && CacheService.config.defaultInvalidationPeriod > Date.now() - result.timeCached) {
            return JSON.parse(result.results);
        }
        const token = await getGraphApiAccessToken();
        const response = await fetch(`https://graph.microsoft.com/v1.0/users/${id}?$select=id,employeeId,displayName,mail,jobTitle,department,companyName`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        if (data) {
            const item = { results: null };
            item.results = JSON.stringify(data);
            cache.putValue(cacheKey, item);
        }
        return data;
    }
    catch (error) {
        console.error(error);
    }
}

export async function getUserPhoto(id) {
    try {
        const cacheKey = `${schema.stores.getUserPhoto}:${id}`;
        const cache = CacheService.getCache(schema, schema.stores.getUserPhoto)
        const result = await cache.getValue(cacheKey);
        if (result && CacheService.config.defaultInvalidationPeriod > Date.now() - result.timeCached) {
            return JSON.parse(result.results);
        }
        const token = await getGraphApiAccessToken();
        const response = await fetch(`https://graph.microsoft.com/v1.0/users/${id}/photo/$value`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        //const data = window.URL.createObjectURL(await response.blob());
        const data = await blobToBase64(await response.blob());
        if (data) {
            const item = { results: null };
            item.results = JSON.stringify(data);
            cache.putValue(cacheKey, item);
        }
        return data;
    }
    catch (error) {
        console.error(error);
    }
}

export async function getMyInfo() {
    return {
        imageUrl: await getMyPhoto(),
        profile: await getMyProfile(),
        userInfo: await getMe(),
        roles: await getMyRole()
    };
}


export async function getUsers() {
    try {
        const cacheKey = `${schema.stores.getUsers}`;
        const cache = CacheService.getCache(schema, schema.stores.getUsers)
        const result = await cache.getValue(cacheKey);
        if (result && CacheService.config.defaultInvalidationPeriod > Date.now() - result.timeCached) {
            return result.results.map(c => JSON.parse(c));
        }

        const token = await getLocalApiAccessToken();
        const response = await fetch('api/users', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        if (Array.isArray(data)) {
            const item = { results: null };
            item.results = data.map(c => JSON.stringify(c));
            cache.putValue(cacheKey, item);
        }
        return data;
    }
    catch (error) {
        console.error(error);
    }
}

export async function getUsersWithGraphApi() {
    try {
        const users = await getUsers();
        const response = await Promise.all(users.map(async (item) => {
            const profile = await getUserProfile(item.id);
            const photo = await getUserPhoto(item.id);
            return {
                ...item,
                ...profile,
                imageUrl: photo
            };
        }));
        return response;
    }
    catch (error) {
        console.error(error);
    }
}
export async function getUser(id) {
    try {
        const cacheKey = `${schema.stores.getUser}:${id}`;
        const cache = CacheService.getCache(schema, schema.stores.getUser)
        const result = await cache.getValue(cacheKey);
        if (result && CacheService.config.defaultInvalidationPeriod > Date.now() - result.timeCached) {
            return JSON.parse(result.results);
        }
        const token = await getLocalApiAccessToken();
        const response = await fetch(`api/users/${id}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        if (data) {
            const item = { results: null };
            item.results = JSON.stringify(data);
            cache.putValue(cacheKey, item);
        }
        return data;
    }
    catch (error) {
        console.error(error);
    }
}

export async function getUserWithGraphApi(id) {
    try {
        const user = await getUser(id);
        const profile = await getUserProfile(id);
        const photo = await getUserPhoto(id);
        return {
            ...user,
            ...profile,
            imageUrl: photo
        };
    }
    catch (error) {
        console.error(error);
    }
}

export async function addUser(values, companyCode) {
    try {
        if (companyCode) {
            const user = await getUserProfile(values.id);
            user.isActive = values.isActive;

            const token = await getLocalApiAccessToken();
            await fetch(`api/company/${companyCode}/users`, {
                method: 'post',
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                body: JSON.stringify(user)
            });
        }
    }
    catch (error) {
        console.error(error);
    }
}

export async function updateUser(key, values) {
    try {

        const user = await getUser(key);
        user.isActive = values.isActive;

        const token = await getLocalApiAccessToken();
        await fetch(`api/users/${key}`, {
            method: 'patch',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(user)
        });
    }
    catch (error) {
        console.error(error);
    }
}

export async function removeUser(key, companyCode) {
    const token = await getLocalApiAccessToken();
    await fetch(`api/company/${companyCode}/users/${key}`, {
        method: 'delete',
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    });
}

export function blobToBase64(blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = _ => {
            resolve(reader.result);
        };
        reader.readAsDataURL(blob);
    });
}

export async function getUsersForMentions(loadOptions) {
    try {
        if (!loadOptions.searchValue) return [];
        const users = await searchUsers(loadOptions.searchValue);
        const response = await Promise.all(users.map(async (item) => {
            const photo = await getUserPhoto(item.id);
            return {
                ...item,
                imageUrl: photo
            };
        }));
        return response;
    }
    catch (error) {
        console.error(error);
    }
}

export async function searchUsers(searchValue) {
    try {
        const cacheKey = `${schema.stores.searchUsers}:${searchValue}`;
        const cache = CacheService.getCache(schema, schema.stores.searchUsers)
        const result = await cache.getValue(cacheKey);
        if (result && CacheService.config.defaultInvalidationPeriod > Date.now() - result.timeCached) {
            return JSON.parse(result.results);
        }
        const token = await getGraphApiAccessToken();
        const response = await fetch(`https://graph.microsoft.com/v1.0/users?$filter=accountEnabled eq true and employeeId ne null&$search="displayName:${searchValue}" OR "mail:${searchValue}"&$select=id,employeeId,displayName,mail,jobTitle,department,companyName&$count=true&$top=6`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'ConsistencyLevel': 'eventual' }
        });
        const data = await response.json();
        if (data.value) {
            const item = { results: null };
            item.results = JSON.stringify(data.value);
            cache.putValue(cacheKey, item);
        }
        return data.value;
    }
    catch (error) {
        console.error(error);
    }
}