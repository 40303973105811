import React, { useMemo, useState } from "react";
import { Button, Nav } from "react-bootstrap";
export default function UserMenu({user, signOut}) {
	const [open, setOpen] = useState(false);
	const personaData = useMemo(() => ({
		imageUrl: user?.imageUrl,
		displayName: user?.profile.displayName,
		jobTitle: user?.profile.jobTitle,
		officeLocation: user?.profile.officeLocation
	}), [user]);

	return (
		<Nav.Item
			as={"li"}
			className={`dropdown user-menu ${open ? "show" : ""}`}
			style={{ borderLeft: "1px solid #eee" }}
		>
			<Nav.Link className="nav-link dropdown-toggle" onClick={()=> (setOpen(!open))}>
				<img className="user-image img-circle" src={personaData.imageUrl} alt="user" />
				
				<span className="d-none d-md-inline">
					{personaData.displayName}
				</span>
				
			</Nav.Link>

			<ul className={`dropdown-menu dropdown-menu-lg dropdown-menu-right ${open ? "show" : ""}`}>
				<li className="user-header bg-dark">
					<img
						className="img-circle elevation-2"
						src={personaData.imageUrl}
						alt="user"
					/>
					<p>
						{personaData.displayName}
						<small>{personaData.jobTitle}</small>
						<small>{personaData.officeLocation}</small>
					</p>
				</li>
				<li className="user-footer">
					<Button
						variant={"default"}
						className={"float-right d-inline btn-flat"}
						onClick={signOut}
					>
						로그아웃
					</Button>
				</li>
			</ul>
		</Nav.Item>
	);
}
