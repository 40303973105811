import "devextreme/dist/css/dx.common.css";
import "./themes/generated/theme.base.css";
import "./themes/generated/theme.additional.css";
import React from "react";
import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { graphApiLoginRequest } from "./authorize/AuthorizeConfig";
import { AuthProvider } from "./contexts/auth";
import AppRoutes from "./app-routes";

export default function App() {
  const element = AppRoutes();

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={{
        ...graphApiLoginRequest,
        prompt: "select_account",
      }}
    >
      <AuthProvider>
        {element}
      </AuthProvider>
    </MsalAuthenticationTemplate>
  );
}
