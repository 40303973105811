import { getLocalApiAccessToken } from "../authorize/AuthorizeService";
import { CacheService } from "utilities/CacheService";
//스키마에 변경이 있으면 버전을 변경한다.
const schema = {
	name: "document",
	stores: {
		document: "document",
	},
	version: 1,
};

export async function getSavedData(documentId) {
	try {
		const cacheKey = `${schema.stores.document}:${documentId}`;
		const cache = CacheService.getCache(schema, schema.stores.document);
		const result = await cache.getValue(cacheKey);
		if (
			result &&
			CacheService.config.defaultInvalidationPeriod >
				Date.now() - result.timeCached
		) {
			return JSON.parse(result.results);
		}
		const token = await getLocalApiAccessToken();
		const response = await fetch(`api/document/${documentId}`, {
			headers: !token ? {} : { Authorization: `Bearer ${token}` },
		});
		const data = await response.json();
		if (data) {
			const item = { results: null };
			item.results = JSON.stringify(data);
			cache.putValue(cacheKey, item);
		}
		return data;
	} catch (error) {
		console.error(error);
	}
}

export async function addSavedData(docData) {
	try {
		const token = await getLocalApiAccessToken();
		const response = await fetch(`api/document`, {
			method: "post",
			headers: !token
				? {}
				: {
						Authorization: `Bearer ${token}`,
						"Content-Type": "application/json",
				  },
			body: JSON.stringify(docData),
		});
		const data = await response.json();
		return data;
	} catch (error) {
		console.error(error);
	}
}

export async function getDocComments(documentId) {
	try {
		if (documentId) {
			const token = await getLocalApiAccessToken();
			const response = await fetch(`api/document/${documentId}/comments`, {
				method: "get",
				headers: !token ? {} : { Authorization: `Bearer ${token}` },
			});
			const data = await response.json();
			return data;
		}
	} catch (error) {
		console.error(error);
	}
}

export async function getDocComment(documentId, commentId) {
	try {
		if (documentId) {
			const token = await getLocalApiAccessToken();
			const response = await fetch(
				`api/document/${documentId}/comments/${commentId}`,
				{
					method: "get",
					headers: !token ? {} : { Authorization: `Bearer ${token}` },
				}
			);
			const data = await response.json();
			return data;
		}
	} catch (error) {
		console.error(error);
	}
}

export async function addDocComment(documentId, comment) {
	try {
		if (documentId) {
			const token = await getLocalApiAccessToken();
			const response = await fetch(`api/document/${documentId}/comments`, {
				method: "post",
				headers: !token
					? {}
					: {
							Authorization: `Bearer ${token}`,
							"Content-Type": "application/json",
					  },
				body: JSON.stringify(comment),
			});
			const data = await response.json();
			return data;
		}
	} catch (error) {
		console.error(error);
	}
}

export async function editDocComment(documentId, comment) {
	try {
		if (documentId) {
			const token = await getLocalApiAccessToken();
			const response = await fetch(
				`api/document/${documentId}/comments/${comment.id}`,
				{
					method: "put",
					headers: !token
						? {}
						: {
								Authorization: `Bearer ${token}`,
								"Content-Type": "application/json",
						  },
					body: JSON.stringify(comment),
				}
			);
			const data = await response.json();
			return data;
		}
	} catch (error) {
		console.error(error);
	}
}

export async function getDocShared(documentId) {
	try {
		if (documentId) {
			const token = await getLocalApiAccessToken();
			const response = await fetch(`api/document/${documentId}/shared`, {
				method: "get",
				headers: !token ? {} : { Authorization: `Bearer ${token}` },
			});
			const data = await response.json();
			return data;
		}
	} catch (error) {
		console.error(error);
	}
}

export async function addDocShared(documentId, shared) {
	try {
		if (documentId) {
			const token = await getLocalApiAccessToken();
			const response = await fetch(`api/document/${documentId}/shared`, {
				method: "post",
				headers: !token
					? {}
					: {
							Authorization: `Bearer ${token}`,
							"Content-Type": "application/json",
					  },
				body: JSON.stringify(shared),
			});
			const data = await response.json();
			return data;
		}
	} catch (error) {
		console.error(error);
	}
}

export async function deleteDocShared(documentId, shared) {
	try {
		if (documentId) {
			const token = await getLocalApiAccessToken();
			await fetch(`api/document/${documentId}/shared/${shared.userId}`, {
				method: "delete",
				headers: !token
					? {}
					: {
							Authorization: `Bearer ${token}`,
							"Content-Type": "application/json",
					  },
			});
		}
	} catch (error) {
		console.error(error);
	}
}

export async function getDocOwner(documentId) {
	try {
		if (documentId) {
			const token = await getLocalApiAccessToken();
			const response = await fetch(`api/document/${documentId}/owner`, {
				method: "get",
				headers: !token ? {} : { Authorization: `Bearer ${token}` },
			});
			const data = await response.json();
			return data;
		}
	} catch (error) {
		console.error(error);
	}
}

export async function getMyDocuments() {
	try {
		const token = await getLocalApiAccessToken();
		const response = await fetch(`api/me/documents`, {
			method: "get",
			headers: !token ? {} : { Authorization: `Bearer ${token}` },
		});
		const data = await response.json();
		return data;
	} catch (error) {
		console.error(error);
	}
}

export async function removeMyDocuments(documentId) {
	try {
		const token = await getLocalApiAccessToken();
		const response = await fetch(`api/me/documents/${documentId}`, {
			method: "delete",
			headers: !token ? {} : { Authorization: `Bearer ${token}` },
		});
		const data = await response.json();
		return data;
	} catch (error) {
		console.error(error);
	}
}

export async function deleteMyDocument() {
	try {
		const token = await getLocalApiAccessToken();
		const response = await fetch(`api/me/documents`, {
			method: "delete",
			headers: !token ? {} : { Authorization: `Bearer ${token}` },
		});
		const data = await response.json();
		return data;
	} catch (error) {
		console.error(error);
	}
}
