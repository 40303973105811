import React, { useState, useEffect } from "react";
import { Stack, Text, DefaultButton } from '@fluentui/react';
import { getDocComments } from 'api/document'
import Comment from './comment'
import HtmlEditor from './editor'

export default function CommentList({ docId }) {
    const [showEditor, setShowEditor] = useState(false);
    const [comments, setComments] = useState([]);

    useEffect(() => {
        if (docId) {
            (async function () {
                const result = await getDocComments(docId);
                if (result) {
                    setComments(result);
                }
            })();
        }
    }, [docId]);

    async function updateComment() {
        var data = await getDocComments(docId);
        setComments(data);
    }

    return (
        <>
            <Stack tokens={{ childrenGap: 's1' }}>
                <Stack>
                    <Text nowrap variant={'mediumPlus'}>Comments</Text>
                </Stack>
                {
                    comments.sort((a, b) => Date.parse(a.createdDate) - Date.parse(b.createdDate)).map(item => <Comment key={item.id} value={item} />)
                }
                <Stack grow>
                    {
                        showEditor ? (
                            <>
                                <Stack style={{ borderTop: "1px solid rgba(0, 0, 0, 0.1)", paddingTop: "10px" }}>
                                    <HtmlEditor showEditor={setShowEditor} isEdit={false} commentData={{ documentId: docId }} update={updateComment} />
                                </Stack>
                            </>
                        ) : (
                            <>
                                <Stack horizontal>
                                    <DefaultButton text="Comment" onClick={() => setShowEditor(true)} />
                                </Stack>
                            </>
                        )
                    }
                </Stack>
            </Stack>
        </>
    );
}