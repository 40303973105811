import React, { useMemo } from "react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import SidebarSearch from "./sidebar-search";
import SidebarMenu from "./sidebar-menu";
import { Link } from "react-router-dom";
import { useAuth } from "contexts/auth";

import "overlayscrollbars/overlayscrollbars.css";
import { getMenuHierarchy } from "utilities/common";

export default function Sidebar(props) {
	const { search } = props;
	const { side } = useAuth();
	const sideMenu = useMemo(() => {
		var data = side?.map((item) => {
			if (item.path && !/^\//.test(item.path)) {
				item.path = `/${item.path}`;
			}
			return item;
		});
		var result = getMenuHierarchy(data);
		return result;
	}, [side]);

	return (
		<aside className={`main-sidebar sidebar-dark-primary elevation-4`}>
			<Link to="/" className="brand-link logo-switch">
				<img
					src={`${process.env.PUBLIC_URL}/vatech-logo-small.png`}
					alt="Brand Logo Small"
					className="brand-image-xs logo-xs"
				/>
				<img
					src={`${process.env.PUBLIC_URL}/vatech-logo-large.png`}
					alt="Brand Logo Large"
					className="brand-image-xs logo-xl"
				/>
			</Link>

			<OverlayScrollbarsComponent defer>
				<div className="sidebar">
					{search ? <SidebarSearch menus={sideMenu} /> : <></>}
					<SidebarMenu menus={sideMenu} />
				</div>
			</OverlayScrollbarsComponent>
		</aside>
	);
}
