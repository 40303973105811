import React, { useEffect } from "react";
import { Nav } from "react-bootstrap";

export default function PushMenu({ options }) {
	const defaultOptions = {
		autoCollapseSize: 992,
		animationSpeed: 300,
	};
	options = { ...defaultOptions, ...options };



	

	function expandSidebar() {
		const bodyElement = document.getElementsByTagName("body")[0];

		if (window.innerWidth <= options.autoCollapseSize) {
			bodyElement.classList.add("sidebar-open");
		}
		bodyElement.classList.remove("sidebar-collapse");
		bodyElement.classList.remove("sidebar-closed");
	}

	function collapseSidebar() {
		const bodyElement = document.getElementsByTagName("body")[0];

		if (window.innerWidth <= options.autoCollapseSize) {
			bodyElement.classList.remove("sidebar-open");
			bodyElement.classList.add("sidebar-closed");
		}
		bodyElement.classList.add("sidebar-collapse");
	}

	function toggleSidebar() {
		const bodyElement = document.getElementsByTagName("body")[0];

		if (bodyElement.classList.contains("sidebar-collapse")) {
			expandSidebar();
		} else {
			collapseSidebar();
		}
	}

	function autoCollapse(resize = false) {
		const bodyElement = document.getElementsByTagName("body")[0];
		if (window.innerWidth <= options.autoCollapseSize) {
			if (!bodyElement.classList.contains("sidebar-open")) {
				collapseSidebar();
			}
		} else if (resize === true) {
			if (bodyElement.classList.contains("sidebar-open")) {
				bodyElement.classList.remove("sidebar-open");
			} else if (bodyElement.classList.contains("sidebar-closed")) {
				expandSidebar();
			}
		}
	}

	function addOverlay() {
		if (!document.getElementById("sidebar-overlay")){
			const wrapper = document.getElementsByClassName("wrapper")[0];
			const overray = document.createElement("div");
			overray.id = "sidebar-overlay";
			overray.addEventListener("click", () => {
				collapseSidebar();
			});
			wrapper.append(overray);
		}
	}

	useEffect(() => {
		addOverlay();
		window.addEventListener("resize", autoCollapse(true));
		return () => {
			window.removeEventListener("resize", autoCollapse(true));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Nav.Link onClick={toggleSidebar}>
			<i className="fas fa-bars" />
		</Nav.Link>
	);
}
