import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { getSavedData } from "api/document";
import * as Pages from "../index";
import CommentList from "components/comment/comment-list";

export default function Document() {
	const { documentId } = useParams();
	const [savedData, setSavedData] = useState();
	const [pageName, setPageName] = useState();
	const [showComments, setShowComments] = useState(false);
	useEffect(() => {
		if (documentId) {
			(async function () {
				const result = await getSavedData(documentId);
				if (result) {
					setSavedData(result.data);
					setPageName(result.pageName)
					setShowComments(true);
				}
			})();
		}
	}, [documentId]);

	return (
		<>
			<PageClassifierMemo savedData={savedData} docId={documentId} pageName={pageName} />
			<div className="content-wrapper" style={{ minHeight: "inherit" }}>
				<section className="content">
					<Container fluid>
						<Row>
							<Col>
								<Card className={`card-outline card-primary ${showComments ? "" : "d-none"}`}>
									<Card.Body>
										<CommentList docId={documentId} />
									</Card.Body>
								</Card>
							</Col>
						</Row>
					</Container>
				</section>
			</div>
		</>
	);
}

function PageClassifier(props) {
	const { pageName } = props;
	switch (pageName) {
		case "Issue":
			return <Pages.Issue {...props} />;
		case "ActionItem":
			return <Pages.ActionItem {...props} />;
		case "Content":
			return <Pages.Content {...props} />;
		case "MessageTrace":
			return <Pages.MessageTrace {...props} />;
		case "SharePointStorage":
			return <Pages.SharePointStorage {...props} />;
		case "Duration":
			return <Pages.Duration {...props} />;
		default:
			return <></>;
	}
}

const PageClassifierMemo = React.memo(PageClassifier);