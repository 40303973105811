import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import * as Pages from "./pages/index";
import CheckPermission from "utilities/CheckPermission";
import FixedSidebarFlatStyle from "layouts/admin-lte/fixed-sidebar-flat-style";

export default function AppRoutes() {
	return (
		<Routes>
			<Route
				path="/"
				element={
					<CheckPermission>
						<FixedSidebarFlatStyle />
					</CheckPermission>
				}
			>
				<Route index={true} element={<Navigate replace to="oasis" />} />
				<Route path="oasis">
					<Route index={true} element={<Pages.Oasis />} />
					<Route path="vts">
						<Route index={true} element={<Navigate replace to="issue" />} />
						<Route path="issue" element={<Pages.Issue />} />
					</Route>
					<Route path="vks">
						<Route
							index={true}
							element={<Navigate replace to="action-item" />}
						/>
						<Route path="action-item" element={<Pages.ActionItem />} />
						<Route path="content" element={<Pages.Content />} />
					</Route>
					<Route path="m365">
						<Route
							index={true}
							element={<Navigate replace to="message-trace" />}
						/>
						<Route path="message-trace" element={<Pages.MessageTrace />} />
						<Route path="storage" element={<Pages.SharePointStorage />} />
					</Route>
					<Route path="approval">
						<Route index={true} element={<Navigate replace to="duration" />} />
						<Route path="duration" element={<Pages.Duration />} />
					</Route>
					<Route path="document">
						<Route path=":documentId" element={<Pages.Document />} />
						<Route index={true} element={<Pages.DocumentList />} />
					</Route>
					{/* <Route path="document" element={<Pages.DocumentList />} /> */}
				</Route>
				<Route path="polaris">
					<Route index={true} element={<Pages.Polaris />} />
					<Route path="document">
						<Route path=":documentId" element={<Pages.Document />} />
						<Route index={true} element={<Pages.DocumentList />} />
					</Route>
				</Route>
				<Route path="admin">
					<Route index={true} element={<Navigate replace to="user" />} />
					<Route path="user" element={<Pages.UserBrowser />} />
					<Route path="role" element={<Pages.Role />} />
					<Route path="permission" element={<Pages.Permission />} />
				</Route>
			</Route>
			<Route path="unauthorized" element={<Pages.Unauthorized />} />
			<Route path="*" element={<Pages.PageNotFound />} />
		</Routes>
	);
}
