import SharedList from "components/shared/shared-list";
import React from "react";
import { Row, Col, Container } from "react-bootstrap";

export default function ContentHeader(props) {
	const { pageName, docId, saveDocument, disableShare  } = props;
	return (
		<div className="content-header">
			<Container fluid>
				<Row className="mb-2">
					<Col>
						<h1 className="m-0">{pageName}</h1>
					</Col>
					<Col>
						{
							disableShare ? (<></>) : (<SharedList docId={docId} saveDocument={saveDocument} />)
						}
					</Col>
				</Row>
			</Container>
		</div>
	);
}
