import React, { useState } from "react";
import { SharedColors } from '@fluentui/theme';
import { Stack, Text, Link, IconButton } from '@fluentui/react';
import HtmlEditor from 'components/comment/editor'
import { getDocComment } from 'api/document'
import {
	Person,
	ViewType,
	PersonCardInteraction,
} from "@microsoft/mgt-react";

import './comment.scss'

export default function Comment(props) {
    const [showEdit, setShowEdit] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [comment, setComment] = useState(props.value);

    async function updateComment() {
        var data = await getDocComment(comment.documentId, comment.id);
        setComment(data);
    }

    return (
        <>
            {
                comment ? (
                    <Stack className={"document-comment"} tokens={{ childrenGap: 's2' }}>
                        <Stack className={"comment-header"} horizontal horizontalAlign="space-between" grow tokens={{ childrenGap: 's2' }}>
                            <Stack horizontal verticalAlign={"center"} style={{ paddingRight: "10px" }} tokens={{ childrenGap: 's2' }}>
                                <Person userId={comment.ownerId} view={ViewType.oneline} personCardInteraction={PersonCardInteraction.hover}/>
                                <Text variant={"small"}>{new Date(`${comment.createdDate}`).toLocaleString()}</Text>
                                {
                                    comment.isModified ?
                                    (
                                        <Text variant={"xSmall"} style={{ color: SharedColors.red10 }}>수정됨</Text>
                                    ) : (
                                        <></>
                                    )
                                }
                            </Stack>
                            <Stack className={"comment-action"}>
                                <IconButton iconProps={{ iconName: 'Edit' }} onClick={() => setShowEdit(true)} />
                            </Stack>
                        </Stack>
                        <Stack grow>
                            {
                                showEdit ? (
                                    <HtmlEditor showEditor={setShowEdit} isEdit={true} commentData={comment} update={updateComment} />
                                ) : (
                                    <>
                                        <Stack verticalAlign="top" className={'dx-card'} style={{ minHeight: "32px", padding: "10px" }}>
                                            <div dangerouslySetInnerHTML={{ __html: comment.content }} />
                                        </Stack>
                                    </>
                                )
                            }
                            {
                                showAdd ? (
                                    <Stack className={"document-comment"} tokens={{ childrenGap: 's2' }}>
                                        <HtmlEditor showEditor={setShowAdd} isEdit={false} commentData={comment} update={updateComment} />
                                    </Stack>
                                ) : (
                                    <Stack>
                                        <Link onClick={() => setShowAdd(true)}>
                                            <Text variant={"small"}>Reply</Text>
                                        </Link>
                                    </Stack>
                                )
                            }
                        </Stack>
                        {
                            comment.children ?
                                (
                                    <>
                                        {comment.children.sort((a, b) => Date.parse(a.createdDate) - Date.parse(b.createdDate))
                                                        .map(item => <Comment key={item.id} value={item} />)}
                                    </>
                                ) : (
                                    <></>
                                )
                        }
                    </Stack>
                ) : (
                    <></>
                )
            }
        </>

    );
}