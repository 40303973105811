import React, { useEffect, useState, useRef, useMemo } from "react";
import {
	Row,
	Col,
	Container,
	Form,
	Card,
	Button,
	Badge,
} from "react-bootstrap";
import Chart, {
	CommonSeriesSettings,
	CommonAxisSettings,
	Tooltip,
	ValueAxis,
	Legend,
	SeriesTemplate,
	Label,
} from "devextreme-react/chart";
import DataGrid, { Column } from "devextreme-react/data-grid";
import ContentHeader from "components/bootstrap/content-header/content-header";
import { getJiraIssueDataOneDay, getOrganizaion } from "api/data";
import { colorScheme, convertReferenceDateToString } from "utilities/common";
import { addSavedData } from "api/document";
import CardLoading from "components/bootstrap/card-loading/card-loading";
const category = {
	New: "최근 7일간 신규 개설 과제",
	Delayed: "지연된 과제",
	Idle: "방치된 과제",
	NotClosed: "미결 과제",
	Upcoming: "마감이 임박한 과제",
};

const defaultSelected = {
	category: "all",
	groupCode: "VN",
	companyCode: "VN",
};

export default function Issue(props) {
	const barChart = useRef(null);
	const [select, setSelect] = useState(defaultSelected);
	const [date, setDate] = useState(null);
	const [groups, setGroups] = useState([]);
	const [loading, setLoading] = useState(true);
	const [rawData, setRawData] = useState(null);
	const [filter, setFilter] = useState(null);
	const { savedData, docId } = props;

	useEffect(() => {
		if (savedData) {
			setSelect({ ...savedData.select });
			setDate(new Date(savedData.date));
			setRawData(savedData.rawData);
			setGroups(savedData.groups);
			setLoading(false);
		}
		if (!savedData) {
			(async function () {
				var result = await getOrganizaion();
				setDate(new Date(result.loadDate));
				setGroups(result.groups);
				var selectedGroup = result.groups.find(
					(c) =>
						c.companyCode === defaultSelected.companyCode &&
						c.groupCode === defaultSelected.groupCode
				);
				selectedGroup.parent = null;
				setSelect({ ...defaultSelected, ...selectedGroup });
				var data = await getJiraIssueDataOneDay(
					new Date(result.loadDate).toISOString()
				);
				setRawData(data);
				setLoading(false);
			})();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function saveDocument() {
		var selectedGroup = groups.find(
			(c) =>
				c.companyCode === select.companyCode && c.groupCode === select.groupCode
		);
		selectedGroup.parent = null;
		select.parent = null;
		var data = {
			pageName: "Issue",
			title: `VTS => 과제 현황 ${convertReferenceDateToString(date)}`,
			data: {
				select: select,
				date: date,
				rawData: chartData.flatMap((c) => c.data),
				groups: groups,
			},
		};
		return await addSavedData(data);
	}

	useEffect(() => {
		var result = [];
		if (select.children) {
			select.children.forEach((item) => {
				if (!Object.keys(item).some((c) => c === "checked")) {
					item.checked = true;
				}
			});
			Array.prototype.push.apply(
				result,
				select.children.sort((a, b) => a.displayOrder - b.displayOrder)
			);
		}
		if (select.members) {
			select.members.forEach((item) => {
				item.companyCode = item.employeeId;
				item.groupCode = item.employeeId;
				item.groupName = item.displayName;
				item.childrenMembers = [item.employeeId];
				if (!Object.keys(item).some((c) => c === "checked")) {
					item.checked = true;
				}
			});
			Array.prototype.push.apply(
				result,
				select.members.sort((a, b) => a.displayOrder - b.displayOrder)
			);
		}
		if (
			savedData &&
			savedData.select.companyCode === select.companyCode &&
			savedData.select.groupCode === select.groupCode
		) {
			result = savedData.select.children
				.filter((d) => d.checked === true)
				.sort((a, b) => a.displayOrder - b.displayOrder);
			Array.prototype.push.apply(
				result,
				savedData.select.members
					.filter((d) => d.checked === true)
					.sort((a, b) => a.displayOrder - b.displayOrder)
			);
		}

		setFilter(result);
	}, [select, savedData]);

	var chartData = useMemo(() => {
		if (!rawData) {
			return [];
		}

		if (select.category === "all") {
			var members = filter
				.filter((d) => d.checked === true)
				.flatMap((c) => c.childrenMembers);
			return Object.keys(category).map((item, idx) => {
				var filterd = rawData
					.filter((c) => c.category === item)
					.filter((c) => members.some((d) => d === c.dataOwnerInfo.employeeId));
				return {
					idx: idx,
					category: category[item],
					data: filterd,
					count: filterd.length,
				};
			});
		}

		if (select.category !== "all") {
			return filter
				.filter((c) => c.checked === true)
				.map((c) => {
					var filterd = rawData
						.filter((d) => d.category === select.category)
						.filter((d) =>
							c.childrenMembers.some((e) => e === d.dataOwnerInfo.employeeId)
						);
					return {
						idx: c.displayOrder,
						category: c.groupName,
						data: filterd,
						count: filterd.length,
					};
				});
		}
	}, [rawData, select, filter]);

	function reRenderChart() {
		if (barChart) barChart.current.instance.render();
	}

	function updateCheckedFilter(event) {
		if (filter?.length > 0) {
			var group = event.target.dataset;
			filter.find(
				(c) =>
					c.companyCode === group.companyCode && c.groupCode === group.groupCode
			).checked = event.target.checked;
			setFilter([...filter]);
		}
	}

	function updateAllChecked(event) {
		filter?.forEach((c) => {
			c.checked = event.target.checked;
		});
		setFilter([...filter]);
	}

	function onPointClick(e) {
		if (select.category === "all") {
			var categoryCode = Object.keys(category).find(
				(c) => category[c] === e.target.originalArgument
			);
			setSelect({ ...select, category: categoryCode });
		}
		if (select.category !== "all") {
			var selectedGroup = filter.find(
				(c) => c.groupName === e.target.originalArgument
			);

			var group = groups.find(
				(c) =>
					c.companyCode === selectedGroup.companyCode &&
					c.groupCode === selectedGroup.groupCode
			);
			if (group?.children?.length > 0 || group?.members?.length > 0) {
				setSelect({
					...select,
					...group,
				});
			}
		}
	}

	function onButtonClick() {
		if (select.parent) {
			var group = groups.find(
				(c) =>
					c.companyCode === select.parent.companyCode &&
					c.groupCode === select.parent.groupCode
			);
			setSelect({
				category: select.category,
				...group,
			});
		}

		if (!select.parent && select.category !== "all") {
			setSelect({
				...select,
				category: "all",
			});
		}
	}
	const hideButton = useMemo(() => {
		if (select.parent) {
			return "";
		}
		if (!select.parent && select.category !== "all") {
			if (savedData && savedData.select.category !== "all") {
				return "d-none";
			}
			return "";
		}
		return "d-none";
	}, [select, savedData]);

	return (
		<div className="content-wrapper" onTransitionEnd={reRenderChart}>
			<ContentHeader
				pageName={"VTS"}
				saveDocument={saveDocument}
				docId={docId}
			/>
			<section className="content">
				<Container fluid>
					<Row>
						<Col md={12}>
							<Card className="card-outline card-info">
								<Card.Body>
									<Form.Check
										inline
										label={"전체선택"}
										defaultChecked={!filter?.some((c) => !c.checked)}
										onChange={updateAllChecked}
									/>
									{filter?.map((item) => {
										return (
											<Form.Check
												key={`${item.companyCode}|${item.groupCode}`}
												id={`${item.companyCode}|${item.groupCode}`}
												data-group-code={item.groupCode}
												data-company-code={item.companyCode}
												label={item.groupName}
												inline
												checked={item.checked}
												onChange={updateCheckedFilter}
											/>
										);
									})}
								</Card.Body>
							</Card>
						</Col>
					</Row>
					<Row>
						<Col md={12}>
							<Card>
								<CardLoading visible={loading} />
								<Card.Header className="text-center">
									<h5>
										<b>
											{select.category === "all"
												? `VTS 과제 현황 ${convertReferenceDateToString(date)}`
												: `${
														category[select.category]
												  } ${convertReferenceDateToString(date)}`}
										</b>
									</h5>
								</Card.Header>
								<Card.Body>
									<Chart
										palette={colorScheme}
										dataSource={chartData}
										ref={barChart}
										onPointClick={onPointClick}
									>
										<CommonSeriesSettings
											valueField="count"
											argumentField="category"
											type="bar"
											ignoreEmptyPoints={true}
										>
											<Label
												visible={true}
												customizeText={(arg) => `${arg.valueText} 건`}
											/>
										</CommonSeriesSettings>
										<CommonAxisSettings>
											<Label overlappingBehavior={"stagger"} />
										</CommonAxisSettings>
										<SeriesTemplate nameField="category" />
										<ValueAxis
											visible={true}
											autoBreaksEnabled={true}
											maxAutoBreakCount={1}
										/>
										<Tooltip enabled={true} />
										<Legend visible={false} />
									</Chart>
									<Button
										variant="outline-secondary"
										className={`bg-white ${hideButton}`}
										style={{
											position: "absolute",
											top: "20px",
											left: "20px",
										}}
										onClick={onButtonClick}
									>
										<i className="fas fa-angle-left"></i> Back
									</Button>
								</Card.Body>
							</Card>
						</Col>
					</Row>
					<Row>
						<Col md={12}>
							<Card>
								<CardLoading visible={loading} />
								<Card.Body>
									<DataGrid
										dataSource={chartData
											.flatMap((c) => c.data)
											.map((c) => {
												var group = filter.find((d) =>
													d.childrenMembers.some(
														(e) => e === c.dataOwnerInfo.employeeId
													)
												);
												return {
													...c,
													groupName: group?.groupName,
												};
											})}
									>
										<Column
											dataField="issueKey"
											width={120}
											cellRender={issueLinkRender}
										/>
										<Column
											dataField="issueStatus"
											width={120}
											cellRender={statusRender}
										/>
										<Column dataField="summary" cellRender={issueLinkRender} />
										<Column dataField="created" dataType="date" width={95} />
										<Column dataField="updated" dataType="date" width={95} />
										<Column dataField="dueDate" dataType="date" width={95} />
										<Column dataField="assignee" dataType="date" width={200} />
										<Column
											dataField="reporter"
											caption="Manager"
											width={200}
										/>
										<Column dataField="facilitator" width={200} />
										<Column dataField="groupName" width={150} />
									</DataGrid>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</section>
		</div>
	);
}

const status = {
	Open: "secondary",
	"In Progress": "warning",
	Resolved: "success",
	Closed: "success",
	Reopened: "secondary",
};

function statusRender(itemData) {
	return <Badge variant={status[itemData.value]}>{itemData.value}</Badge>;
}

function issueLinkRender(itemData) {
	return (
		<a
			href={`https://vts.vatech.com/browse/${itemData.data.issueKey}`}
			rel="noreferrer"
			target="_blank"
			className="text-secondary"
		>
			{itemData.value}
		</a>
	);
}
