import { LogLevel } from "@azure/msal-browser";

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito


export const msalConfig = {
    auth: {
        clientId: "acb83567-1e68-4e32-92dc-fc48d50b80d7",
        authority: "https://login.microsoftonline.com/755b4806-5c36-4bd0-a63b-ddb726138cec",
        redirectUri: process.env.REACT_APP_REDIRECTURI
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: isIE || isEdge || isFirefox
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }

                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    // case LogLevel.Info:
                    //     console.info(message);
                    //     return;
                    // case LogLevel.Verbose:
                    //     console.debug(message);
                    //     return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            }
        }
    }
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const localApiLoginRequest = {
    scopes: ["api://acb83567-1e68-4e32-92dc-fc48d50b80d7/.default"]
};

export const graphApiLoginRequest = {
    scopes: ["https://graph.microsoft.com/.default"]
};
