import React from "react";
import { Card, Container } from "react-bootstrap";
import ContentHeader from "components/bootstrap/content-header/content-header";
import DataGrid, {
	Column,
	Editing,
	LoadPanel,
	Button,
} from "devextreme-react/data-grid";
import { getMyDocuments, removeMyDocuments } from "api/document";
import { Link } from "react-router-dom";
import { People, Person, ViewType } from "@microsoft/mgt-react";
import CustomStore from "devextreme/data/custom_store";
import { useAuth } from "contexts/auth";

export default function DocumentList() {
	const { user } = useAuth();
	const store = new CustomStore({
		key: "id",
		load: (loadOptions) => getMyDocuments(),
		remove: (key) => removeMyDocuments(key),
	});

	return (
		<div className="content-wrapper" onTransitionEnd={() => {}}>
			<ContentHeader pageName={"공유된 페이지"} disableShare={true} />
			<section className="content">
				<Container fluid>
					<Card>
						<Card.Body>
							<DataGrid dataSource={store}>
								<LoadPanel enabled={true} />
								<Editing allowDeleting={true} useIcons={true} />
								<Column dataField="id" visible={false} />
								<Column
									dataField="createdDate"
									dataType={"date"}
									caption={"생성일"}
									width={95}
									defaultSortOrder="desc"
								/>
								<Column
									dataField="ownerId"
									caption={"소유자"}
									width={150}
									cellRender={ownerRender}
								/>
								<Column dataField="pageName" visible={false} />
								<Column
									dataField="title"
									cellRender={(itemData) => (
										<Link to={itemData?.data?.id}>{itemData?.data?.title}</Link>
									)}
								/>
								<Column
									dataField="sharedUser"
									width={150}
									cellRender={sharedUserRender}
								/>
								<Column type="buttons">
									<Button
										name="delete"
										visible={(e) => (user.userInfo.id === e.row.data.ownerId)}
									/>
								</Column>
							</DataGrid>
						</Card.Body>
					</Card>
				</Container>
			</section>
		</div>
	);
}

function ownerRender(itemData) {
	return <Person userId={itemData.data.ownerId} view={ViewType.oneline} />;
}

function sharedUserRender(itemData) {
	return <People userIds={itemData.data.sharedUser} showMax="5" />;
}
