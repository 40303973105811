import React, { useEffect, useState } from "react";
import { Nav, Collapse } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { checkMenuPath } from "utilities/common";

export default function SidebarMenuItem(props) {
	const { item } = props;
	const [open, setOpen] = useState(false);
	const [closed, setClosed] = useState(true);
	const [active, setActive] = useState(false);
	const [hasChildren, setHasChildren] = useState(false);
	const { pathname } = useLocation();

	useEffect(() => {
		setOpen(checkMenuPath(pathname, [item]));
		setClosed(!checkMenuPath(pathname, [item]));
	}, [item, pathname]);

	useEffect(() => {
		setHasChildren(item?.children?.length > 0);
	}, [item]);

	useEffect(() => {
		var isActive = [item]
			?.flat()
			.some((c) => c.path.toLowerCase() === pathname.toLowerCase());
		if (
			item.path === "/oasis/document" &&
			pathname
				.toLowerCase()
				.split("/")
				.filter(Boolean)
				.some((c) => c === "document")
		) {
			isActive = true;
		}
		setActive(isActive);
	}, [item, pathname]);

	const childrenStyle = {
		display: closed ? "none" : "block",
	};

	return (
		<Nav.Item as="li" className={hasChildren && open ? "menu-open" : ""}>
			<Link
				to={hasChildren ? "#" : item?.path}
				className={`nav-link` + (active ? " active" : "")}
				onClick={() => {
					if (hasChildren) setOpen(!open);
				}}
			>
				<i className={`nav-icon ${item?.icon}`} />
				<p>
					{item?.text}
					{hasChildren ? <i className={`right fas fa-angle-left`} /> : <></>}
				</p>
			</Link>
			{hasChildren ? (
				<Collapse
					in={open}
					onEnter={() => setClosed(false)}
					onExited={() => setClosed(true)}
				>
					<Nav as="ul" className={`nav-treeview`} style={childrenStyle}>
						{item.children.map((c) => {
							return <SidebarMenuItem key={c.id} item={c} />;
						})}
					</Nav>
				</Collapse>
			) : (
				<></>
			)}
		</Nav.Item>
	);
}
