export const colorScheme = [
	"#4E79A7",
	"#F28E2B",
	"#E15759",
	"#76B7B2",
	"#59A14F",
	"#EDC948",
	"#B07AA1",
	"#FF9DA7",
	"#9C755F",
	"#BAB0AC",
];

export function getMenuHierarchy(data) {
	var roots = data
		.filter((c) => !data.some((d) => d.id === c.parentId))
		.sort((a, b) => a.order - b.order);
	var result = [];
	roots.forEach((c) => {
		result.push({
			...c,
			children: getMenuChildren(data, c),
		});
	});
	return result;
}

function getMenuChildren(menus, menu) {
	var children = menus
		.filter((c) => c.parentId === menu.id)
		.sort((a, b) => a.order - b.order);
	var result = [];
	children.forEach((c) => {
		result.push({
			...c,
			children: getMenuChildren(menus, c),
		});
	});
	return result;
}

export function checkMenuPath(pathName, menus) {
	for (let menu of menus) {
		if (menu.path === pathName) {
			return true;
		}
		if (menu.children !== undefined && menu.children.length > 0) {
			let childsearch = checkMenuPath(pathName, menu.children);
			if (childsearch === true) {
				return true;
			}
		}
	}
	return false;
}

export function groupBy(array, key) {
	var keyValue = Array.from(new Set(array.map((c) => c[key])));
	return keyValue.map((item) => {
		return {
			key: item,
			value: array.filter((c) => c[key] === item),
		};
	});
}

export function getCategorys(data) {
	var categoryInfos = data.map((c) => {
		c.dataCategoryInfo.push({
			depth: 0,
			categoryCode: 0,
			categoryName: "root",
		});
		return c.dataCategoryInfo;
	});

	var categories = Array.from(
		new Set(categoryInfos.flat().map((c) => JSON.stringify(c)))
	).map((c) => JSON.parse(c));

	categories.forEach((c) => {
		var data = categoryInfos
			.filter((d) =>
				d.some((e) => e.depth === c.depth && e.categoryCode === c.categoryCode)
			)
			.flat();
		if (data.find((d) => d.depth === c.depth - 1))
			c.parent = JSON.parse(
				JSON.stringify(data.find((d) => d.depth === c.depth - 1))
			);
		c.children = Array.from(
			new Set(
				data
					.filter((d) => d.depth === c.depth + 1)
					.map((c) => JSON.stringify(c))
			)
		).map((c) => JSON.parse(c));
	});

	return categories;
}

export function categorySearchFilter(search, categories) {
	if (search.depth === 0) return { children: categories };

	for (let category of categories) {
		if (category.categoryCode === search.categoryCode) {
			return category;
		}
		if (category.children !== undefined && category.children.length > 0) {
			let childsearch = categorySearchFilter(search, category.children);
			if (childsearch !== undefined) {
				return childsearch;
			}
		}
	}
	return undefined;
}

export function convertStringToDateForLatestDate(data) {
	Object.keys(data).forEach((c) => {
		if (typeof data[c] === "object") {
			data[c] = convertStringToDateForLatestDate(data[c]);
		} else {
			data[c] = new Date(data[c]);
		}
	});
	return data;
}

export function roundToTwo(num) {
	return +(Math.round(num + "e+2") + "e-2");
}

export function convertReferenceDateToString(date) {
	if (!(date instanceof Date)) {
		return "";
	}
	return `(${date.toLocaleDateString([], {
		hour: "2-digit",
		hourCycle: "h23",
	})} - ${Intl.DateTimeFormat().resolvedOptions().timeZone})`;
}
