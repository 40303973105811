
import { openDB } from 'idb';
import { Providers, ProviderState } from '@microsoft/mgt-react'

export class CacheService {

    static getCache(schema, storeName) {
        const key = `${schema.name}/${storeName}`;
        if (!this.isInitialized) {
            this.init();
        }
        if (!this.cacheStore.has(storeName)) {
            this.cacheStore.set(key, new CacheStore(schema, storeName));
        }
        return this.cacheStore.get(key);
    }

    static clearCaches() {
        this.cacheStore.forEach(x => indexedDB.deleteDatabase(x.getDBName()));
    }

    static get config() {
        return this.cacheConfig;
    }

    static init() {
        let previousState;
        if (Providers.globalProvider) {
            previousState = Providers.globalProvider.state;
        }
        Providers.onProviderUpdated(() => {
            if (previousState === ProviderState.SignedIn && Providers.globalProvider.state === ProviderState.SignedOut) {
                this.clearCaches();
            }
            previousState = Providers.globalProvider.state;
        });
        this.isInitialized = true;
    }
}

CacheService.cacheStore = new Map();
CacheService.isInitialized = false;
CacheService.cacheConfig = {
    defaultInvalidationPeriod: 3600000, //단위 밀리세컨드, 1시간
}


export class CacheStore {
    constructor(schema, store) {
        if (!(store in schema.stores)) {
            throw Error('"store" must be defined in the "schema"');
        }
        this.schema = schema;
        this.store = store;
    }

    async getValue(key) {
        if (!window.indexedDB) {
            return null;
        }
        try {
            return (await this.getDb()).get(this.store, key);
        }
        catch (e) {
            return null;
        }
    }

    async putValue(key, item) {
        if (!window.indexedDB) {
            return;
        }
        try {
            await (await this.getDb()).put(this.store, Object.assign(Object.assign({}, item), { timeCached: Date.now() }), key);
        }
        catch (e) {
            return;
        }
    }

    async clearStore() {
        if (!window.indexedDB) {
            return;
        }
        try {
            (await this.getDb()).clear(this.store);
        }
        catch (e) {
            return;
        }
    }

    getDBName() {
        return `eis-${this.schema.name}`;
    }
    getDb() {
        return openDB(this.getDBName(), this.schema.version, {
            upgrade: (db, oldVersion, newVersion, transaction) => {
                for (const storeName in this.schema.stores) {
                    if (this.schema.stores.hasOwnProperty(storeName)) {
                        db.objectStoreNames.contains(storeName) || db.createObjectStore(storeName);
                    }
                }
            }
        });
    }
}