import React from "react";
import { Container } from "react-bootstrap";
export default function Unauthorized() {
	return (
		<div className="wrapper">
			<section className="content">
				<Container fluid>
					<h1>접근 권한이 없습니다.</h1>
				</Container>
			</section>
		</div>
	);
}
