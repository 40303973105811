import React, { useRef, useState } from "react";
import { useBoolean, useId } from "@fluentui/react-hooks";
import { useNavigate } from "react-router-dom";
import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { Dialog, DialogType, DialogFooter } from "@fluentui/react/lib/Dialog";
import { PeoplePicker, UserType } from "@microsoft/mgt-react";
import { addDocShared } from "api/document";
import { getUserProfile } from "api/users";

const dialogContentProps = {
	type: DialogType.normal,
	title: "공유하기",
	closeButtonAriaLabel: "Close",
};

export default function SharedButton(props) {
	const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
	const sharePeoplePicker = useRef(null);
	const { saveDocument } = props;
	const [disableShareButton, setDisableShareButton] = useState(false);

	const buttonId = useId("share-button");
	const navigate = useNavigate();

	async function doShare() {
		setDisableShareButton(true);
		var doc = await saveDocument();
		await Promise.all(
			sharePeoplePicker.current.selectedPeople.map(async (item) => {
				var response = await getUserProfile(item.id);

				await addDocShared(doc.id, {
					id: response.id,
					displayName: response.displayName,
				});
			})
		).finally(() => {
			setDisableShareButton(false);
			toggleHideDialog();
			navigate(`/oasis/document/${doc.id}`, {
				replace: false,
			});
		});
	}

	return (
		<>
			<DefaultButton
				id={buttonId}
				className={"float-right"}
				text="공유하기"
				iconProps={{ iconName: "Share" }}
				onClick={toggleHideDialog}
			/>
			<Dialog
				hidden={hideDialog}
				onDismiss={toggleHideDialog}
				dialogContentProps={dialogContentProps}
			>
                <PeoplePickerMemo ref={sharePeoplePicker} />
				<DialogFooter>
					<PrimaryButton
						onClick={doShare}
						text="Share"
						disabled={disableShareButton}
					/>
					<DefaultButton onClick={toggleHideDialog} text="Cancel" />
				</DialogFooter>
			</Dialog>
		</>
	);
}

function SharedPeoplePicker(props, ref) {
	return (
		<PeoplePicker
			ref={ref}
			userType={UserType.user}
			userFilters="userType eq 'Member' and accountEnabled eq true"
		/>
	);
}

const PeoplePickerMemo = React.memo(React.forwardRef(SharedPeoplePicker));
