import React, { useEffect, useRef, useMemo, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import ContentHeader from "components/bootstrap/content-header/content-header";
import {
	colorScheme,
	groupBy,
	roundToTwo,
	convertReferenceDateToString,
} from "utilities/common";
import { getSharePointStorage7Days, getOrganizaion } from "api/data";
import Chart, {
	CommonSeriesSettings,
	Tooltip,
	ValueAxis,
	Legend,
	Series,
} from "devextreme-react/chart";
import DataGrid, { Column, Paging } from "devextreme-react/data-grid";
import { addSavedData } from "api/document";
import CardLoading from "components/bootstrap/card-loading/card-loading";
export default function SharePointStorage(props) {
	const chart = useRef(null);

	const [date, setDate] = useState(new Date(new Date().toLocaleDateString()));
	const [loading, setLoading] = useState(true);
	const [rawData, setRawData] = useState(null);
	const { savedData, docId } = props;

	useEffect(() => {
		if (savedData) {
			setDate(new Date(savedData.date));
			setRawData(savedData.rawData);
			setLoading(false);
		}
		if (!savedData) {
			(async function () {
				var result = await getOrganizaion();
				setDate(new Date(result.loadDate));

				var data = await getSharePointStorage7Days(
					new Date(result.loadDate).toISOString()
				);
				setRawData(data);
				setLoading(false);
			})();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function saveDocument() {
		var data = {
			pageName: "SharePointStorage",
			title: `M365 => SharePoint 전체 사용량 ${convertReferenceDateToString(
				date
			)}`,
			data: {
				date: date,
				rawData: rawData,
			},
		};
		return await addSavedData(data);
	}

	var chartData = useMemo(() => {
		var result = [];
		if (rawData) {
			var groupByDate = groupBy(rawData, "loadDate");
			result = groupByDate
				.map((item) => {
					return {
						...item,
						key: new Date(item.key),
						storageUsageSum:
							item.value.reduce((a, b) => a + b.storageUsageCurrent, 0) /
							1024 /
							1024,
					};
				})
				.sort((a, b) => b.key - a.key);
		}
		return result;
	}, [rawData]);

	var gridData = useMemo(() => {
		var result = [];
		if (chartData.length > 0) {
			var temp = chartData.find((c) => true);
			if (temp) {
				result = temp.value
					.sort((a, b) => b.storageUsageCurrent - a.storageUsageCurrent)
					.map((c) => {
						return {
							...c,
							storageQuota: c.storageQuota / 1024,
							storageUsageCurrent: c.storageUsageCurrent / 1024,
							limitUsageRate: (c.storageUsageCurrent / c.storageQuota) * 100,
							totalUsageRate:
								((c.storageUsageCurrent / 1024 / 1024) * 100) /
								temp.storageUsageSum,
						};
					});
			}
		}
		return result;
	}, [chartData]);

	function reRenderChart() {
		if (chart) chart.current.instance.render();
	}

	return (
		<div
			className="content-wrapper"
			onTransitionEnd={() => {
				reRenderChart();
			}}
		>
			<ContentHeader
				pageName={"SharePoint 사용량"}
				saveDocument={saveDocument}
				docId={docId}
			/>
			<section className="content">
				<Container fluid>
					<Row>
						<Col md={6}>
							<Card className="card-outline card-primary">
								<CardLoading visible={loading} />
								<Card.Header className="text-center">
									<h5>
										<b>{`SharePoint 전체 사용량`}</b>
									</h5>
								</Card.Header>
								<Card.Body>
									<Chart
										ref={chart}
										dataSource={chartData}
										palette={colorScheme}
									>
										<CommonSeriesSettings argumentField="key" />
										<Series valueField="storageUsageSum" name="사용량" />
										<ValueAxis visible={true} inverted={false} />
										<Tooltip
											enabled={true}
											customizeTooltip={customizeTooltip}
										/>
										<Legend visible={false} />
									</Chart>
								</Card.Body>
							</Card>
						</Col>
						<Col md={6}>
							<Card className="card-outline card-secondary">
								<CardLoading visible={loading} />
								<Card.Header className="text-center">
									<h5>
										<b>{`SharePoint 사용량`}</b>
									</h5>
								</Card.Header>
								<Card.Body>
									<DataGrid
										dataSource={gridData}
										wordWrapEnabled={true}
										columnAutoWidth={true}
									>
										<Paging defaultPageSize={10} />
										<Column dataField="title" caption={"Site Name"} />
										<Column
											dataField="storageQuota"
											caption={"Storage Limit (GB)"}
											alignment="right"
											customizeText={(data) => `${roundToTwo(data.value)} GB`}
										/>
										<Column
											dataField="storageUsageCurrent"
											caption={"Storage Used (GB)"}
											alignment="right"
											customizeText={(data) => `${roundToTwo(data.value)} GB`}
										/>
										<Column
											dataField="limitUsageRate"
											caption={"제한용량 대비 사용비중 (%)"}
											alignment="right"
											customizeText={(data) => `${roundToTwo(data.value)} %`}
										/>
										<Column
											dataField="totalUsageRate"
											caption={"전체용량 대비 사용비중 (%)"}
											alignment="right"
											customizeText={(data) => `${roundToTwo(data.value)} %`}
										/>
									</DataGrid>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</section>
		</div>
	);
}

function customizeTooltip(pointInfo) {
	return {
		html: `<div>
				<div class="tooltip-header">
					${pointInfo.argument.toLocaleString()}
				</div>
				<div class="tooltip-body">
					<div class="series-name">
						<span class='top-series-name'>${pointInfo.seriesName}</span>: ${roundToTwo(
			pointInfo.value
		)} TB
					</div>
				</div>
			</div>`,
	};
}
