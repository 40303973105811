import React from 'react';
import { Container } from 'react-bootstrap';

export default function PageNotFound() {
  return (
    <div className="wrapper">
      <section className='content'>
        <Container fluid>
          <h1>페이지를 찾을 수 없습니다.</h1>
        </Container>
      </section>
    </div>
  );
}
