import React, { useEffect, useRef, useMemo, useState } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import ContentHeader from "components/bootstrap/content-header/content-header";
import {
	colorScheme,
	getCategorys,
	convertReferenceDateToString,
} from "utilities/common";
import { getConfluenceContents30Days, getOrganizaion } from "api/data";
import Chart, {
	CommonSeriesSettings,
	CommonAxisSettings,
	Tooltip,
	ValueAxis,
	Legend,
	SeriesTemplate,
	Label,
} from "devextreme-react/chart";
import DataGrid, { Column } from "devextreme-react/data-grid";
import { addSavedData } from "api/document";
import CardLoading from "components/bootstrap/card-loading/card-loading";

const defaultSelected = {
	categoryCode: 0,
};

export default function Content(props) {
	const barChart = useRef(null);
	const [select, setSelect] = useState(defaultSelected);
	const [date, setDate] = useState(null);
	const [categories, setCategories] = useState([]);
	const [groups, setGroups] = useState([]);
	const [loading, setLoading] = useState(true);
	const [rawData, setRawData] = useState(null);
	const [filter, setFilter] = useState(null);
	const { savedData, docId } = props;

	useEffect(() => {
		if (savedData) {
			setSelect(savedData.select);
			setDate(new Date(savedData.date));
			setGroups(savedData.groups);
			setRawData(savedData.rawData);
			setCategories(savedData.categories);
			setLoading(false);
		}
		if (!savedData) {
			(async function () {
				var result = await getOrganizaion();
				setDate(new Date(result.loadDate));
				setGroups(result.groups);
				var data = await getConfluenceContents30Days(new Date(result.loadDate));
				setRawData(data);
				var temp = getCategorys(data);
				setCategories(temp);
				var selectedCategories = temp.find(
					(c) => c.categoryCode === defaultSelected.categoryCode
				);
				selectedCategories.parent = null;
				setSelect(selectedCategories);
				setLoading(false);
			})();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function saveDocument() {
		select.parent = null;
		var data = {
			pageName: "Content",
			title: `VKS => 제안제도 ${convertReferenceDateToString(date)}`,
			data: {
				select: select,
				date: date,
				rawData: chartData.flatMap((c) => c.data),
				groups: groups,
				categories: categories,
			},
		};
		return await addSavedData(data);
	}

	useEffect(() => {
		var result = [];
		if (select.children) {
			select.children.forEach((item) => {
				if (!Object.keys(item).some((c) => c === "checked")) {
					item.checked = true;
				}
			});
			Array.prototype.push.apply(
				result,
				select.children.sort((a, b) => a.categoryCode - b.categoryCode)
			);
		}
		if (savedData && savedData.select.categoryCode === select.categoryCode) {
			result = savedData.select.children
				.filter((d) => d.checked === true)
				.sort((a, b) => a.categoryCode - b.categoryCode);
		}
		setFilter(result);
	}, [select, savedData]);

	var chartData = useMemo(() => {
		if (!rawData) {
			return [];
		}
		return filter
			.filter((c) => c.checked === true)
			.map((c) => {
				var filterd = rawData.filter((d) =>
					d.dataCategoryInfo.some((e) => e.categoryCode === c.categoryCode)
				);
				return {
					idx: c.categoryCode,
					category: c.categoryName,
					data: filterd,
					count: filterd.length,
				};
			});
	}, [rawData, filter]);

	function reRenderChart() {
		if (barChart) barChart.current.instance.render();
	}

	function updateCheckedFilter(event) {
		if (filter?.length > 0) {
			var category = event.target.dataset;
			filter.find(
				(c) => c.categoryCode === Number(category.categoryCode)
			).checked = event.target.checked;
			setFilter([...filter]);
		}
	}

	function updateAllChecked(event) {
		filter?.forEach((c) => {
			c.checked = event.target.checked;
		});
		setFilter([...filter]);
	}

	function onPointClick(e) {
		var category = categories.find(
			(c) =>
				c.categoryCode ===
				filter.find((d) => d.categoryName === e.target.originalArgument)
					.categoryCode
		);
		if (category?.children?.length > 0) {
			setSelect(category);
		}
	}

	function onButtonClick() {
		if (select.parent) {
			setSelect(
				categories.find((c) => c.categoryCode === select.parent.categoryCode)
			);
		}
	}

	const hideButton = useMemo(() => {
		if (!select.parent) {
			return "d-none";
		}
		return "";
	}, [select]);

	return (
		<div
			className="content-wrapper"
			onTransitionEnd={() => {
				reRenderChart();
			}}
		>
			<ContentHeader
				pageName={"제안제도"}
				saveDocument={saveDocument}
				docId={docId}
			/>
			<section className="content">
				<Container fluid>
					<Row>
						<Col md={12}>
							<Card className="card-outline card-info">
								<Card.Body>
									<Form.Check
										inline
										label={"전체선택"}
										defaultChecked={!filter?.some((c) => !c.checked)}
										onChange={updateAllChecked}
									/>
									{filter?.map((item) => {
										return (
											<Form.Check
												key={item.categoryCode}
												id={item.categoryCode}
												label={item.categoryName}
												data-category-code={item.categoryCode}
												inline
												checked={item.checked}
												onChange={updateCheckedFilter}
											/>
										);
									})}
								</Card.Body>
							</Card>
						</Col>
					</Row>
					<Row>
						<Col md={12}>
							<Card>
								<CardLoading visible={loading} />
								<Card.Header className="text-center">
									<h5>
										<b>
											{`최근 30일간 등록된 신규 제안수 ${convertReferenceDateToString(
												date
											)}`}
										</b>
									</h5>
								</Card.Header>
								<Card.Body>
									<Chart
										palette={colorScheme}
										dataSource={chartData}
										ref={barChart}
										onPointClick={onPointClick}
									>
										<CommonSeriesSettings
											valueField="count"
											argumentField="category"
											type="bar"
											ignoreEmptyPoints={true}
										>
											<Label
												visible={true}
												customizeText={(arg) => `${arg.valueText} 건`}
											/>
										</CommonSeriesSettings>
										<CommonAxisSettings>
											<Label overlappingBehavior={"stagger"} />
										</CommonAxisSettings>
										<SeriesTemplate nameField="category" />
										<ValueAxis
											visible={true}
											autoBreaksEnabled={true}
											maxAutoBreakCount={1}
										/>
										<Tooltip enabled={true} />
										<Legend visible={false} />
									</Chart>
									<Button
										variant="outline-secondary"
										className={`bg-white ${hideButton}`}
										style={{
											position: "absolute",
											top: "20px",
											left: "20px",
										}}
										onClick={onButtonClick}
									>
										<i className="fas fa-angle-left"></i> Back
									</Button>
								</Card.Body>
							</Card>
						</Col>
					</Row>
					<Row>
						<Col md={12}>
							<Card>
								<CardLoading visible={loading} />
								<Card.Body>
									<DataGrid
										dataSource={chartData.flatMap((c) =>
											c.data.map((d) => {
												var group = groups.find(
													(e) =>
														e.companyCode === d.dataOwnerInfo.companyCode &&
														e.groupCode === d.dataOwnerInfo.groupCode
												);
												return {
													...d,
													category: c.category,
													groupName: group?.groupName,
												};
											})
										)}
									>
										<Column dataField="category" width={200} />
										<Column dataField="title" cellRender={contentLinkRender} />
										<Column
											dataField="creationDate"
											caption={"등록일"}
											dataType={"date"}
											width={95}
										/>
										<Column
											dataField="dataOwnerInfo.displayName"
											caption="작성자"
											width={200}
										/>
										<Column dataField="groupName" caption="Group" width={150} />
									</DataGrid>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</section>
		</div>
	);
}
function contentLinkRender(itemData) {
	return (
		<a
			href={`https://vks.vatech.com/pages/viewpage.action?pageId=${itemData.data.contentId}`}
			rel="noreferrer"
			target="_blank"
			className="text-secondary"
		>
			{itemData.value}
		</a>
	);
}
