import { getLocalApiAccessToken, getAccount} from '../authorize/AuthorizeService';
import { getUserProfile, getUserPhoto } from './users';
import { CacheService } from 'utilities/CacheService'

const schema = {
    name: 'company',
    stores: {
        myCompany: 'myCompany',
        allCompanies: 'allCompanies',
    },
    version: 1
}

export async function getMyCompany() {
    try {
        const account = getAccount();
        const cacheKey = `${schema.stores.myCompany}:${account.localAccountId}`;
        const cache = CacheService.getCache(schema, schema.stores.myCompany)
        const result = await cache.getValue(cacheKey);
        if (result && CacheService.config.defaultInvalidationPeriod > Date.now() - result.timeCached) {
            return result.results.map(c => JSON.parse(c));
        }
        const token = await getLocalApiAccessToken();
        const response = await fetch(`api/me/company`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        if (Array.isArray(data)) {
            const item = { results: null };
            item.results = data.map(c => JSON.stringify(c));
            cache.putValue(cacheKey, item);
        }
        return data;
    }
    catch (error) {
        console.error(error);
    }
}

export async function getAllCompanies() {
    try {
        const cacheKey = `${schema.stores.allCompanies}`;
        const cache = CacheService.getCache(schema, schema.stores.allCompanies)
        const result = await cache.getValue(cacheKey);
        if (result && CacheService.config.defaultInvalidationPeriod > Date.now() - result.timeCached) {
            return result.results.map(c => JSON.parse(c));
        }
        const token = await getLocalApiAccessToken();
        const response = await fetch(`api/company`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        if (Array.isArray(data)) {
            const item = { results: null };
            item.results = data.map(c => JSON.stringify(c));
            cache.putValue(cacheKey, item);
        }
        return data;
    }
    catch (error) {
        console.error(error);
    }
}

export async function getCompanyAssignedUsers(companyCode) {
    try {
        if (!companyCode) return [];
        const token = await getLocalApiAccessToken();
        const response = await fetch(`api/company/${encodeURIComponent(companyCode)}/users`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        return data;
    }
    catch (error) {
        console.error(error);
    }
}

export async function getCompanyAssignedUsersWithGraphApi(companyCode) {
    try {
        const users = await getCompanyAssignedUsers(companyCode);
        if (Array.isArray(users)) {
            const response = await Promise.all(users.map(async (item) => {
                const profile = await getUserProfile(item.id);
                const photo = await getUserPhoto(item.id);
                return {
                    ...item,
                    ...profile,
                    imageUrl: photo
                };
            }));
            return response;
        }
    }
    catch (error) {
        console.error(error);
    }
}