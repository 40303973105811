import React from "react";
import SharedButton from "./shared-button";
import SharedFacepile from "./shared-facepile";
import "./shared.scss";

export default function SharedList(props) {
	const { docId } = props;
	return (
		<>{docId ? <SharedFacepile {...props} /> : <SharedButton {...props} />}</>
	);
}
