import { getLocalApiAccessToken, getAccount } from '../authorize/AuthorizeService';
import { CacheService } from 'utilities/CacheService'

//스키마에 변경이 있으면 버전을 변경한다.
const schema = {
    name: 'menu',
    stores: {
        getMySideMenu: 'getMySideMenu',
        getMyHeaderMenu: 'getMyHeaderMenu',
        getAllSideMenu: 'getAllSideMenu',
        getAllHeaderMenu: 'getAllHeaderMenu',
        getAllNavMenus: 'getAllNavMenus'
    },
    version: 2
}

export async function getMySideMenu(category) {
    try {
        const account = getAccount();
        if (!category) category = 'oasis';
        const cacheKey = `${schema.stores.getMySideMenu}:${category}:${account.localAccountId}`;
        const cache = CacheService.getCache(schema, schema.stores.getMySideMenu)
        const result = await cache.getValue(cacheKey);
        if (result && CacheService.config.defaultInvalidationPeriod > Date.now() - result.timeCached) {
            return result.results.map(c => JSON.parse(c));
        }
        const token = await getLocalApiAccessToken();
        const response = await fetch(`api/me/menu/side?category=${encodeURIComponent(category)}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        if (Array.isArray(data)) {
            const item = { results: null };
            item.results = data.map(c => JSON.stringify(c));
            cache.putValue(cacheKey, item);
        }
        return data;
    }
    catch (error) {
        console.error(error);
    }
}


export async function getMyHeaderMenu() {
    try {
        const account = getAccount();
        const cacheKey = `${schema.stores.getMyHeaderMenu}:${account.localAccountId}`;
        const cache = CacheService.getCache(schema, schema.stores.getMyHeaderMenu)
        const result = await cache.getValue(cacheKey);
        if (result && CacheService.config.defaultInvalidationPeriod > Date.now() - result.timeCached) {
            return result.results.map(c => JSON.parse(c));
        }
        const token = await getLocalApiAccessToken();
        const response = await fetch('api/me/menu/header', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        if (Array.isArray(data)) {
            const item = { results: null };
            item.results = data.map(c => JSON.stringify(c));
            cache.putValue(cacheKey, item);
        }
        return data;
    }
    catch (error) {
        console.error(error);
    }
}

export async function getAllSideMenu() {
    try {

        const cacheKey = `${schema.stores.getAllSideMenu}`;
        const cache = CacheService.getCache(schema, schema.stores.getAllSideMenu)
        const result = await cache.getValue(cacheKey);
        if (result && CacheService.config.defaultInvalidationPeriod > Date.now() - result.timeCached) {
            return result.results.map(c => JSON.parse(c));
        }
        const token = await getLocalApiAccessToken();
        const response = await fetch(`api/menu/side`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        if (Array.isArray(data)) {
            const item = { results: null };
            item.results = data.map(c => JSON.stringify(c));
            cache.putValue(cacheKey, item);
        }
        return data;
    }
    catch (error) {
        console.error(error);
    }
}


export async function getAllHeaderMenu() {
    try {
        const cacheKey = `${schema.stores.getAllHeaderMenu}`;
        const cache = CacheService.getCache(schema, schema.stores.getAllHeaderMenu)
        const result = await cache.getValue(cacheKey);
        if (result && CacheService.config.defaultInvalidationPeriod > Date.now() - result.timeCached) {
            return result.results.map(c => JSON.parse(c));
        }
        const token = await getLocalApiAccessToken();
        const response = await fetch('api/menu/header', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        if (Array.isArray(data)) {
            const item = { results: null };
            item.results = data.map(c => JSON.stringify(c));
            cache.putValue(cacheKey, item);
        }
        return data;
    }
    catch (error) {
        console.error(error);
    }
}

export async function getAllNavMenus() {
    try {
        const cacheKey = `${schema.stores.getAllNavMenus}`;
        const cache = CacheService.getCache(schema, schema.stores.getAllNavMenus)
        const result = await cache.getValue(cacheKey);
        if (result && CacheService.config.defaultInvalidationPeriod > Date.now() - result.timeCached) {
            return result.results.map(c => JSON.parse(c));
        }
        const token = await getLocalApiAccessToken();
        const response = await fetch('api/menu', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        if (Array.isArray(data)) {
            const item = { results: null };
            item.results = data.map(c => JSON.stringify(c));
            cache.putValue(cacheKey, item);
        }
        return data;
    }
    catch (error) {
        console.error(error);
    }

}
