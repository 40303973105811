import React, { useState, useMemo, useRef } from "react";
import { Dropdown } from "@fluentui/react";
import {
	Persona,
	PersonaSize,
	PersonaPresence,
} from "@fluentui/react/lib/Persona";
import DataGrid, {
	Paging,
	Editing,
	Column,
	Form,
	Toolbar,
	Item,
} from "devextreme-react/data-grid";
import {
	getAllCompanies,
	getCompanyAssignedUsersWithGraphApi,
} from "api/company";
import {
	getRoles,
	getRoleAssignedUsersWithGraphApi,
	assigneUserToRole,
	removeUserFromRole,
} from "api/role";
import { getUserWithGraphApi } from "api/users";
import {
	NormalPeoplePicker,
	PeoplePickerItem,
} from "@fluentui/react/lib/Pickers";
import CustomStore from "devextreme/data/custom_store";
import ContentHeader from "components/bootstrap/content-header/content-header";
import { Container } from "react-bootstrap";

export default function Role() {
	const [companies, setCompanies] = useState([]);
	const [roles, setRoles] = useState([]);
	const [companyUser, setCompanyUser] = useState([]);
	const [selectedCompany, setSelectedCompany] = useState();
	const [selectedRole, setSelectedRole] = useState();
	const dataGrid = useRef(null);

	useMemo(() => {
		(async function () {
			const result = await getAllCompanies();
			if (Array.isArray(result)) {
				setCompanies(
					result.map((item) => ({ key: item.code, text: item.name }))
				);
				setSelectedCompany(result?.find((c) => true)?.code);
			}
		})();
	}, []);
	useMemo(() => {
		if (selectedCompany) {
			(async function () {
				const roles = await getRoles(selectedCompany);
				if (Array.isArray(roles)) {
					setRoles(roles.map((item) => ({ key: item.id, text: item.name })));
					setSelectedRole(roles?.find((c) => true)?.id);
				}

				const users = await getCompanyAssignedUsersWithGraphApi(
					selectedCompany
				);
				if (Array.isArray(users)) {
					setCompanyUser(
						users.map((item) => ({
							...item,
							key: item.id,
							text: item.displayName,
							secondaryText: item.jobTitle,
						}))
					);
				}
			})();
		}
	}, [selectedCompany]);

	const store = new CustomStore({
		key: "id",
		byKey: (key) => getUserWithGraphApi(key),
		load: (loadOptions) => getRoleAssignedUsersWithGraphApi(selectedRole),
		insert: (values) => assigneUserToRole(values, selectedRole),
		remove: (key) => removeUserFromRole(key, selectedRole),
	});

	return (
		<div className="content-wrapper" onTransitionEnd={() => {}}>
			<ContentHeader pageName={"역할"} disableShare />
			<section className="content">
				<Container fluid>
					<DataGrid
						ref={dataGrid}
						dataSource={store}
						showBorders={true}
						showRowLines={true}
						rowAlternationEnabled={true}
						columnAutoWidth={true}
					>
						<Paging enabled={true} />
						<Editing mode="form" allowAdding={true} allowDeleting={true}>
							<Form>
								<Item dataField="id" isRequired={true} />
							</Form>
						</Editing>
						<Column
							caption="이름"
							dataField="id"
							visible={false}
							editCellRender={UserPicker}
						/>
						<Column
							caption="이름"
							dataField="displayName"
							cellRender={(cellData) => (
								<Persona
									imageUrl={cellData.data.imageUrl}
									text={cellData.data.displayName}
									size={PersonaSize.size24}
									presence={PersonaPresence.none}
									hidePersonaDetails={false}
								/>
							)}
						/>
						<Column
							caption="사번"
							dataField="employeeId"
							allowEditing={false}
						/>
						<Column caption="이메일" dataField="mail" allowEditing={false} />
						<Column
							caption="직책/직급"
							dataField="jobTitle"
							allowEditing={false}
						/>
						<Column
							caption="회사"
							dataField="companyName"
							allowEditing={false}
						/>
						<Column
							caption="마지막 로그인(UTC)"
							dataField="lastLogonTimeUtc"
							dataType="datetime"
							allowEditing={false}
							visible={false}
						/>
						<Column
							caption="계정 사용"
							dataField="isActive"
							dataType="boolean"
							visible={false}
						/>
						<Toolbar>
							<Item location="before">
								<Dropdown
									placeholder="Select a Role"
									options={roles}
									styles={{ dropdown: { width: 200 } }}
									defaultSelectedKey={roles?.find((c) => true)?.key}
									onChange={(event, option, index) =>
										setSelectedRole(option.key)
									}
								/>
							</Item>
							<Item location="before">
								<Dropdown
									placeholder="Select a Company"
									options={companies}
									styles={{ dropdown: { width: 200 } }}
									defaultSelectedKey={companies?.find((c) => true)?.key}
									onChange={(event, option, index) =>
										setSelectedCompany(option.key)
									}
								/>
							</Item>
							<Item name="addRowButton" />
						</Toolbar>
					</DataGrid>
				</Container>
			</section>
		</div>
	);

	function onFilterChanged(filterText, currentPersonas, limitResults) {
		if (filterText) {
			let filteredPersonas = filterPersonasByText(filterText);
			filteredPersonas = removeDuplicates(filteredPersonas, currentPersonas);
			return limitResults
				? filteredPersonas.slice(0, limitResults)
				: filteredPersonas;
		} else {
			return [];
		}
	}

	function filterPersonasByText(filterText) {
		var dataGridItem = dataGrid.current.instance.getDataSource().items();
		return companyUser
			.filter(
				(item) => dataGridItem.filter((c) => c.id === item.id).length === 0
			)
			.filter((item) => doesTextStartWith(item.text, filterText));
	}

	function returnMostRecentlyUsed(currentPersonas) {
		var dataGridItem = dataGrid.current.instance.getDataSource().items();
		return removeDuplicates(
			companyUser.filter(
				(item) => dataGridItem.filter((c) => c.id === item.id).length === 0
			),
			currentPersonas
		);
	}

	function UserPicker(params) {
		return (
			<>
				<NormalPeoplePicker
					onResolveSuggestions={onFilterChanged}
					onEmptyInputFocus={returnMostRecentlyUsed}
					pickerSuggestionsProps={{
						noResultsFoundText: "No results found",
						loadingText: "Loading",
					}}
					getTextFromItem={getTextFromItem}
					itemLimit={1}
					onRenderItem={(props) => (
						<PeoplePickerItem
							{...props}
							item={{ ...props.item, showSecondaryText: false }}
						/>
					)}
					onItemSelected={(selectedItem) => {
						params.data.id = selectedItem.id;
						return selectedItem;
					}}
					resolveDelay={300}
				/>
			</>
		);
	}
}

function doesTextStartWith(text, filterText) {
	return text.toLowerCase().indexOf(filterText.toLowerCase()) === 0;
}

function removeDuplicates(personas, possibleDupes) {
	return personas.filter(
		(persona) => !listContainsPersona(persona, possibleDupes)
	);
}

function listContainsPersona(persona, personas) {
	if (!personas || !personas.length || personas.length === 0) {
		return false;
	}
	return personas.filter((item) => item.text === persona.text).length > 0;
}

function getTextFromItem(persona) {
	return persona.text;
}
