import { useAuth } from "contexts/auth";
import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import HeaderNavbarItem from "./header-navbar-item";
import PushMenu from "./push-menu";
import UserMenu from "./user-menu";

export default function HeaderNavbar(props) {
	const { header, user, signOut } = useAuth();

	
	return (
		<Navbar className={"main-header"}>
			<Nav as={"ul"}>
				<Nav.Item as={"li"}>
					<PushMenu />
				</Nav.Item>
				{Array.isArray(header) ? (
					header.filter(c => c.text !== "ADMIN").map((item) => {
						return <HeaderNavbarItem item={item} key={item.id} />;
					})
				) : (
					<></>
				)}
			</Nav>

			<Nav as={"ul"} className={"ml-auto"}>
				<UserMenu user={user} signOut={signOut} />
				{
					Array.isArray(header) && header?.some((c) => c.text === "ADMIN") ? (
					<Nav.Item as={"li"} style={{ borderLeft: "1px solid #eee" }}>
						<Link to={"/admin"} className="nav-link">
							<i className="fas fa-cog"></i>
						</Link>
					</Nav.Item>
				) : (
					<></>
				)}
			</Nav>
		</Navbar>
	);
}
