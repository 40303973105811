import React, { useState, useRef, useMemo } from "react";
import { InputGroup, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const defualtOptions = {
	arrowSign: "->",
	minLength: 2,
	maxResults: 7,
	highlightName: true,
	highlightPath: false,
	highlightClass: "text-light",
	notFoundText: "No element found!",
};

export default function SidebarSearch(props) {
	const { menus } = props;
	const options = { ...defualtOptions, ...props.options };
	const [open, setOpen] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const resultGroup = useRef(null);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const searchItems = useMemo(() => menus?.map((c) => parseItem(c)).flat(), [menus]);
	function parseItem(item, pathname = []) {
		const itemObject = {
			name: item.text,
			path: pathname,
			link: item.path,
		};

		item.pathname = pathname;
		if (item.children.length > 0) {
			const newPath = item.pathname.concat([itemObject.name]);
			return item.children.map((c) => {
				return parseItem(c, newPath);
			});
		} else {
			return itemObject;
		}
	}

	var timeout = null;
	function onKeyUpSearchBox(event) {
		var children = resultGroup.current.children;
		if (searchResults.length > 0) {
			var focusedIndex = -1;
			for (let i = 0; i < children.length; i++) {
				if (document.activeElement === children[i]) {
					focusedIndex = i;
					break;
				}
			}

			if (event.keyCode === 38) {
				event.preventDefault()
				children[focusedIndex - 1].focus();
			}
			if (event.keyCode === 40) {
				event.preventDefault()
				children[0].focus();
			}
		}

		if (timeout) clearTimeout(timeout);
		timeout = setTimeout(function () {
			setOpen(true);
			setSearchValue(event.target.value.toLowerCase());
		}, 500);
	}
	function onKeyDownSearchResult(event) {
		var children = resultGroup.current.children;
		var focusedIndex = -1;
		for (let i = 0; i < children.length; i++) {
			if (document.activeElement === children[i]) {
				focusedIndex = i;
				break;
			}
		}

		if (event.keyCode === 38) {
			event.preventDefault()

			if (focusedIndex < 1) {
				children[children.length - 1].focus();
			} else {
				children[focusedIndex - 1].focus();
			}
		}
		if (event.keyCode === 40) {
			event.preventDefault()

			if (focusedIndex === -1 || focusedIndex === children.length - 1) {
				children[0].focus();
			} else {
				children[focusedIndex + 1].focus();
			}
		}
	}

	const searchResults = useMemo(() => {
		if (searchValue.length < options.minLength) return [];
		var searchResults = searchItems.filter((c) =>
			c.name.toLowerCase().includes(searchValue)
		);
		var endResults = searchResults.slice(0, options.maxResults);
		if (endResults.length === 0) {
			return [];
		}
		return endResults;
	}, [searchValue, searchItems, options.minLength, options.maxResults]);

	return (
		<Form
			as={"div"}
			inline
			className={`mt-3 ${open ? "sidebar-search-open" : ""}`}
		>
			<InputGroup>
				<Form.Control
					className="form-control-sidebar"
					placeholder="Search"
					aria-label="Search"
					onKeyUp={onKeyUpSearchBox}
				/>
				<InputGroup.Append>
					<Button
						className="btn-sidebar"
						onClick={() => setOpen(!open)}
					>
						<i className={`fas ${open ? "fa-times" : "fa-search"} fa-fw`} />
					</Button>
				</InputGroup.Append>
			</InputGroup>
			<div className="sidebar-search-results">
				<div
					className="list-group"
					onKeyDown={onKeyDownSearchResult}
					ref={resultGroup}
				>
					{renderItems(searchResults)}
				</div>
			</div>
		</Form>
	);

	function renderItems(items) {
		if (items?.length > 0) {
			return items.map((item) => renderItem(item.name, item.link, item.path));
		} else {
			return renderItem(options.notFoundText, "#", []);
		}
	}

	function renderItem(name, link, path) {
		path = path?.join(` ${options.arrowSign} `);
		link = decodeURI(link);

		if (options.highlightName || options.highlightPath) {
			const regExp = new RegExp(searchValue, "gi");

			if (options.highlightName) {
				name = name?.replace(regExp, (str) => {
					return `<strong class="${options.highlightClass}">${str}</strong>`;
				});
			}

			if (options.highlightPath) {
				path = path?.replace(regExp, (str) => {
					return `<strong class="${options.highlightClass}">${str}</strong>`;
				});
			}
		}

		return (
			<Link
				key={name}
				to={decodeURIComponent(link)}
				className="list-group-item"
				onClick={() => setOpen(false)}
			>
				<div
					className="search-title"
					dangerouslySetInnerHTML={{ __html: name }}
				/>
				<div
					className="search-path"
					dangerouslySetInnerHTML={{ __html: path }}
				/>
			</Link>
		);
	}
}
