import React from "react";
import { Container } from "react-bootstrap";
import ContentHeader from "components/bootstrap/content-header/content-header";
export default function Dashboard() {
	return (
		<div className="content-wrapper" onTransitionEnd={() => {}}>
			<ContentHeader pageName={"대시보드"} />
			<section className="content">
				<Container fluid>

				</Container>
			</section>
		</div>
	);
}
