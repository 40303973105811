
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useAuth } from "contexts/auth";
import { checkMyPermission } from "api/permission";
import { useEffect } from "react";

export default function CheckPermission({ children }) {
	const { loading } = useAuth();
	let location = useLocation();
	let navigate = useNavigate();
    const basePath = useBasePath();

	useEffect(() => {
		if (location.key !== "default" && location.pathname !== "/") {
			if (!loading) {
				(async function () {
					const result = await checkMyPermission(basePath);
					if (!result) navigate("/Unauthorized", { replace: true, state: { from: location } });
				})();
			}
		}
	}, [basePath, loading, location, navigate])

	
	return children;
}

export const useBasePath = () => {
    const location = useLocation();
    const params = useParams();

    return Object.values(params).reduce(
        (path, param) => path.replace('/' + param, ''),
        location.pathname,
    );
};