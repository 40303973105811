import React from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function HeaderNavbarItem({ item }) {
	return (
		<Nav.Item as={"li"} className="d-none d-sm-inline-block">
			<Link to={item?.path} className="nav-link">
				{item?.text}
			</Link>
		</Nav.Item>
	);
}
