import { localApiLoginRequest, graphApiLoginRequest } from './AuthorizeConfig';
import { msalInstance } from "../index";


export async function getLocalApiAccessToken() {
    const account = getAccount();
    const response = await msalInstance.acquireTokenSilent({
        ...localApiLoginRequest,
        account: account
    });
    return response.accessToken;
}

export async function getGraphApiAccessToken() {
    const account = getAccount();
    const response = await msalInstance.acquireTokenSilent({
        ...graphApiLoginRequest,
        account: account
    });
    return response.accessToken;
}

export function getAccount() {
    const account = msalInstance.getActiveAccount();
    if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
    return account;
}
